<template>
  <main class="LoginPage">
    <LoginForm @handleSubmitLogin="handleSubmitLogin" />
    <!-- <PasswordChangeModal
      v-if="isConfirmOpened"
      @handleClickChangePassword="handleClickChangePassword"
      @handleClickCancel="handleClickCancel"
    /> -->
  </main>
</template>

<script>
import { mapState } from "vuex";

import * as RESTAPI from "@/shared/communication/RESTAPI";
import CryptoJS from "crypto-js";

import LoginForm from "@/page/login/component/LoginForm.vue";
// import PasswordChangeModal from "@/page/login/component/PasswordChangeModal.vue";

export default {
  name: "LoginPage",
  components: {
    LoginForm,
    // PasswordChangeModal,
  },
  // data() {
  //   return {
  //     isConfirmOpened: false,
  //   };
  // },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async handleSubmitLogin(userLoginId, userPassword) {
      // let hashUserId = CryptoJS.SHA256(userLoginId).toString();
      let hashUserPassword = CryptoJS.SHA256(userPassword).toString();

      const data = await RESTAPI.postLogin(userLoginId, hashUserPassword);
      //**************** 중복 로그인 확인 ****************
      if (data.success) {
        let userData = await RESTAPI.getUser(data.result.id);
        let userDataResult = userData.result;
        if (userData.success) {
          let userInfo = userDataResult;

          if (userData.result.loggedIn) {
            //**************** 중복 로그인****************
            if (
              confirm(
                `${this.$t("otherAlert.duplicatedLoginState")} ${this.$t(
                  "otherAlert.disconnect"
                )}`
              )
            ) {
              this.$store.dispatch("sendMessageBySocket", data.result.id);
              this.$store.dispatch("login", userInfo);
              this.$router.push("/");
            }
          } else {
            this.$store.dispatch("login", userInfo);
            this.$router.push("/");
          }
        }
      } else {
        switch (data.response.status) {
          case 404:
            this.$store.commit(
              "openAlert",
              `${this.$t("otherAlert.nonexistenceId")}\n${this.$t(
                "otherAlert.pleaseCheckAgain"
              )}`
            );

            break;
          case 401:
            this.$store.commit(
              "openAlert",
              this.$t("otherAlert.wrongPassword")
            );
            break;
          default:
            this.$store.commit(
              "openAlert",
              this.$t("otherAlert.ErrorOccurredWithErrorMessage", {
                errorMessage: data.message,
              })
            );
            break;
        }
      }
    },

    // handleClickCancel() {
    //   return (this.isConfirmOpened = false);
    // },
    // async handleClickChangePassword(newPassword) {
    //   let hashPassword = CryptoJS.SHA256(newPassword).toString();
    //   let hashUserName = CryptoJS.SHA256(this.user.name).toString();

    //   let tempUserInfo = {
    //     userId: hashUserName,
    //     userPw: hashPassword,
    //   };

    //   const data = await RESTAPI.putLogin(this.user.id, tempUserInfo);

    //   if (data.status === 200) {
    //     return (this.isConfirmOpened = false);
    //   }

    //   return this.$store.commit("openAlert", data.statusText);
    // },
  },
};
</script>

<style lang="scss">
.LoginPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @include flexbox;
  background-color: $main_black;
}
</style>
