class DroneControl{
  constructor(mqttClient, module){
    this.mqttClient = mqttClient;
    this.module = module;

    setInterval(()=>{
      this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_connection/CONNECTION`,`{"time": 10, "droneId" : ${this.module.id}}`);  
    }, 1000);
  }
  modulePose(data){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/MODULE_POSE`,data)
  }
  initialize(drone_type, action){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_sirius_mode/SET_SIRIUS`,`{"drone_type": "${drone_type}", "action": "${action}"}`)
  }
  arm(){
    this.setModeGuide();
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_basic_command/SET_ARMING`,`{"set_arming": true}`);
    console.log("Arm");
  }
  disArm(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_basic_command/SET_ARMING`,`{"set_arming": false}`);    
    console.log("Disarm");
  }
  takeOff(alt){
    let regex = /[^0-9.]/g;
    if(alt == ""){
      return "아무런값도 입력하지 않으셨습니다."
    }
    else if(regex.test(alt)){
      return "숫자를 입력하세요."                      
    }
    else if(alt < 1 || 100 < alt){
      return "1~100 사이의 값을 입력하세요."
    }
    else{
      this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_basic_command/SET_TAKEOFF`,`{"set_takeoff_altitude": ${parseFloat(alt)}}`);
      console.log(`Take off ${alt}m`);
      return false
    }
  } 
  land(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_basic_command/SET_LAND`,`{"set_land": true}`);
    console.log("Land");
  } //드론 착륙
  setModeGuide(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_basic_command/SET_MODE`,`{"set_mode": "GUIDED"}`);
    console.log("Guided");
  }  //드론 모드 가이드로 변경
  setModeLoiter(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_basic_command/SET_MODE`,`{"set_mode": "LOITER"}`);
    console.log("Loiter");
  }   //드론 모드 로이터로 변경
  returnHome(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_basic_command/SET_MODE`,`{"set_mode": "RTL"}`);
    console.log("RTL");
  }
  failsafe(percentage,takeoffHeight){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_uav_failsafe/CRITERIA`, `{"percentage" : ${percentage}, "timeout" : 30, "takeoffHeight" : ${takeoffHeight}}`);
  }
  pointCloudSave(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_ftp/POINT_CLOUD_SAVE`,`{"point_cloud_save": true}`);
    console.log("Pointcloud Save");
  }
  reboot(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_reboot/REBOOT`,`{"reboot": true}`);
    console.log("Reboot");
  }
  gpsMode(gps_mode){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_gps_mode_transition/SET_GPS_MODE`,`{"gps_mode": ${gps_mode}}`);
    console.log(`Gps Mode : ${gps_mode}`);
  }
  maxSpeed(max_speed){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_params/SET_MAX_SPEED`,`{"max_speed": "${max_speed}"}`);
    console.log(`Max Speed : ${max_speed}`);
  }
  maxYawSpeed(max_yaw_speed){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_params/SET_MAX_YAW_SPEED`,`{"max_yaw_speed": "${max_yaw_speed}"}`);
    console.log(`Max Yaw Speed : ${max_yaw_speed}`);
  }
  obstacleDistance(obstacle_distance){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_params/SET_OBSTACLE_DISTANCE`,`{"obstacle_distance": "${obstacle_distance}"}`);
    console.log(`Obstacle Distance : ${obstacle_distance}`);
  }
  waypointSpeed(waypoint_speed){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_params/SET_WAYPOINT_SPEED`,`{"waypoint_speed": "${waypoint_speed}"}`);
    console.log(`Waypoint Speed : ${waypoint_speed}`);
  }
  waypointYawSpeed(waypoint_yaw_speed){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_params/SET_WAYPOINT_YAW_SPEED`,`{"waypoint_yaw_speed": "${waypoint_yaw_speed}"}`);
    console.log(`Waypoint Yaw Speed : ${waypoint_yaw_speed}`);
  }
  missionStart(waypointNumber){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/MISSION_START`,`{"waypoint_number" : ${waypointNumber}}`)
  }
  setWaypoint(poseX,poseY,poseZ,yaw,gimbalPitch,type,collisionInfo){
    var yawRad = yaw * Math.PI / 180;
    var oriZ = Math.sin(yawRad/2);
    var oriW = Math.cos(yawRad/2);
    if(collisionInfo && collisionInfo?.length != 0){
      this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/SET_WAYPOINT`,`{"pose_x": ${poseX}, "pose_y" : ${poseY}, "pose_z" : ${poseZ}, "ori_x" : ${0}, "ori_y" : ${0}, "ori_z" : ${oriZ}, "ori_w" : ${oriW}, "gimbal_pitch" : ${gimbalPitch}, "type" : "${type}", "collisionInfoList" : ${JSON.stringify(collisionInfo)}}`);
    }
    else{
      this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/SET_WAYPOINT`,`{"pose_x": ${poseX}, "pose_y" : ${poseY}, "pose_z" : ${poseZ}, "ori_x" : ${0}, "ori_y" : ${0}, "ori_z" : ${oriZ}, "ori_w" : ${oriW}, "gimbal_pitch" : ${gimbalPitch}, "type" : "${type}", "collisionInfoList" : null}`);
    }
  }
  mission(gimbalPitchArray, shouldCapture){
    if(gimbalPitchArray){
      this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/MISSION`,`{"type": "camera_mission", "gimbal_pitch_array" : [${gimbalPitchArray}], "should_capture" : ${shouldCapture}}`);
    }
    else{
      this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/MISSION`,`{"type": "camera_mission", "gimbal_pitch_array" : [], "should_capture" : ${shouldCapture}}`);
    }

  } 
  stop(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/STOP`, `{"stop" : true}`)
    console.log("Stop");
  }
  // expStop(){
  //   this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/EXP_STOP`, `{"stop" : true}`)
  // }
  // expStart(){
  //   this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/EXP_START`, `{"start" : true}`)
  // }
  ftpUploadInfo(data){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_ftp/UPLOAD`, `${data}`)
  }
  ftpDownloadInfo(data){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_ftp/DOWNLOAD`, `${data}`)
  }
  localizationConfig(minX,maxX,minY,maxY,minZ,maxZ){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_sirius_localization_config/LOCALIZATION_CONFIG`, `{ "minX": ${minX}, "maxX" : ${maxX}, "minY": ${minY}, "maxY" : ${maxY}, "minZ": ${minZ}, "maxZ" : ${maxZ}}`);
  }
  dataManagementDirectory(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_DIRECTORY`, `{'require' : true}`);
  }
  dataManagementErase(data){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_ERASE`, `{ "info": ${data}}`);
  }
  dataManagementCopy(data){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_COPY`, data);
  }
  dataManagementUSB(){
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_USB`, `{'require' : true}`);
  }
}

export default DroneControl
