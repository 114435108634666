<template>
  <div class="mapping_left_side_status">
    <div class="mapping_left_side_status_header">
      <div class="mapping_left_side_status_header_title">
        {{ $t("term.state") }}
      </div>
      <div
        class="mapping_left_side_setting_button button"
        @click="openSettingModal(true)"
      ></div>
    </div>
    <div class="mapping_left_side_status_body">
      <div class="mapping_left_side_status_body_top">
        <div class="mapping_left_side_status_body_top_content">
          <div class="mapping_left_side_status_body_top_content_title">
            {{ $t("term.mode") }}
          </div>
          <div class="mapping_left_side_status_body_top_content_value">
            {{ droneData.mode }}
          </div>
        </div>
        <div class="mapping_left_side_status_body_top_content" id="gps">
          <div class="mapping_left_side_status_body_top_content_title">GPS</div>
          <div class="mapping_left_side_status_body_top_content_value">
            <div class="mapping_gps_number">{{ droneData.gps.satellites }}</div>
            <div class="mapping_hdop">{{ droneData.gps.eph }}</div>
          </div>
        </div>
        <div class="mapping_left_side_status_body_top_content">
          <div class="mapping_left_side_status_body_top_content_title">
            {{ $t("term.battery") }}
          </div>
          <div class="mapping_left_side_status_body_top_content_value">
            {{ droneData.battery }}%
          </div>
        </div>
      </div>
      <div class="mapping_left_side_status_body_bottom">
        <div class="mapping_left_side_status_body_bottom_left">
          <div class="mapping_left_side_status_body_bottom_content" id="lte">
            <div class="mapping_left_side_status_body_bottom_content_title">
              LTE
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_value"
              :class="getLteImg"
            ></div>
          </div>
          <div class="mapping_left_side_status_body_bottom_content" id="rc">
            <div class="mapping_left_side_status_body_bottom_content_title">
              {{ $t("term.radioControl") }}
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_value"
              :class="{ on: droneData.rc }"
            ></div>
          </div>
          <div class="mapping_left_side_status_body_bottom_content">
            <div class="mapping_left_side_status_body_bottom_content_title">
              {{ $t("term.speed") }}
            </div>
            <div class="mapping_left_side_status_body_bottom_content_value">
              <div class="mapping_speed_value">{{ droneData.speed }}</div>
              <div class="mapping_speed_unit">m/s</div>
              <div class="mapping_fly_time">{{ droneData.flightTime }}</div>
            </div>
          </div>
          <div
            class="mapping_left_side_status_body_bottom_content"
            id="warning"
            @click="changeStatusModalState"
          >
            <div class="mapping_left_side_status_body_bottom_content_title">
              {{ $t("term.alarm") }}
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_value"
            ></div>
          </div>
          <div class="mapping_left_side_status_body_bottom_content">
            <div class="mapping_left_side_status_body_bottom_content_title">
              {{ $t("term.longitude") }}
            </div>
            <div class="mapping_left_side_status_body_bottom_content_value">
              {{ droneData.pose.lon }}
            </div>
          </div>
          <div class="mapping_left_side_status_body_bottom_content">
            <div class="mapping_left_side_status_body_bottom_content_title">
              X
            </div>
            <div class="mapping_left_side_status_body_bottom_content_value">
              {{ droneData.pose.x }}
            </div>
          </div>
        </div>
        <div class="mapping_left_side_status_body_bottom_right">
          <div class="mapping_left_side_status_body_bottom_content">
            <div
              class="mapping_left_side_status_body_bottom_content_title sensor"
            >
              {{ $t("term.lidar") }}
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_value sensor"
            >
              <div
                class="mapping_left_side_status_body_bottom_content_sensor_state"
                :class="{ on: droneData.sensorState.lidar }"
              ></div>
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_title sensor"
            >
              IMU
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_value sensor"
            >
              <div
                class="mapping_left_side_status_body_bottom_content_sensor_state"
                :class="{ on: droneData.sensorState.imu }"
              ></div>
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_title sensor"
            >
              {{ $t("term.encoder") }}
            </div>
            <div
              class="mapping_left_side_status_body_bottom_content_value sensor"
            >
              <div
                class="mapping_left_side_status_body_bottom_content_sensor_state"
                :class="{ on: droneData.sensorState.encoder }"
              ></div>
            </div>
          </div>
          <div class="mapping_left_side_status_body_bottom_content_alt">
            <div class="mapping_alt_unit">
              {{ $t("term.relativeAltitude") }} (m)
            </div>
            <div class="mapping_alt_value">{{ droneData.pose.alt }}</div>
            <div class="mapping_alt_img">
              <div
                class="mapping_alt_gage"
                :style="{ height: gageHeight }"
              ></div>
            </div>
          </div>
          <div class="mapping_left_side_status_body_bottom_content">
            <div class="mapping_left_side_status_body_bottom_content_title">
              {{ $t("term.latitude") }}
            </div>
            <div class="mapping_left_side_status_body_bottom_content_value">
              {{ droneData.pose.lat }}
            </div>
          </div>
          <div class="mapping_left_side_status_body_bottom_content">
            <div class="mapping_left_side_status_body_bottom_content_title">
              Y
            </div>
            <div class="mapping_left_side_status_body_bottom_content_value">
              {{ droneData.pose.y }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["changeSettingModalState", "changeStatusModalState"],
  props: {
    droneData: Object,
  },
  computed: {
    getLteImg() {
      switch (this.droneData.lte) {
        case 0:
          return null;
        case 1:
          return "one";
        case 2:
          return "two";
        case 3:
          return "three";
        case 4:
          return "four";
        default:
          return null;
      }
    },
    getWarningColor() {
      if (this.droneData.msg.length != 0) {
        if (
          this.droneData.msg[this.droneData.msg.length - 1].severity >= 0 &&
          this.droneData.msg[this.droneData.msg.length - 1].severity <= 3
        ) {
          return "red";
        } else if (
          this.droneData.msg[this.droneData.msg.length - 1].severity >= 4 &&
          this.droneData.msg[this.droneData.msg.length - 1].severity <= 5
        ) {
          return "orange";
        } else if (
          this.droneData.msg[this.droneData.msg.length - 1].severity >= 6 &&
          this.droneData.msg[this.droneData.msg.length - 1].severity <= 7
        ) {
          return "green";
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    gageHeight() {
      return this.droneData.pose.alt > 100
        ? "100%"
        : `${this.droneData.pose.alt}%`;
    },
  },
  methods: {
    openSettingModal(boolean) {
      this.$emit("changeSettingModalState", boolean);
    },
    changeStatusModalState() {
      this.$emit("changeStatusModalState");
    },
  },
};
</script>

<style lang="scss">
.mapping_left_side_status {
  width: 100%;
  height: calc(100% - 215px);
  padding-top: 20px;
}
.mapping_left_side_status_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
}
.mapping_left_side_status_header_title {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  height: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  color: $main_white;
}
.mapping_left_side_setting_button {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/setting.svg");
  background-size: 15px 15px;
}
.mapping_left_side_setting_button:hover {
  background-image: url("@/../public/images/setting_hover.svg");
  background-size: 15px 15px;
}
.mapping_left_side_status_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 60px);
  margin: 10px 0px 20px 0px;
}
.mapping_left_side_status_body_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% / 7 - 5px);
  width: 100%;
}
.mapping_left_side_status_body_top_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc((100% - 20px) / 3);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 5px;
}
.mapping_left_side_status_body_top_content_title {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: $main_white;
}
.mapping_left_side_status_body_top_content_value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
  color: rgb(1, 250, 254);
  font-size: 1.2rem;
}
.mapping_gps_number {
  height: 50%;
  font-size: 1.2rem;
}
.mapping_hdop {
  height: 50%;
  font-size: 1.2rem;
}
.mapping_left_side_status_body_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% / 7 * 6 - 5px);
  width: 100%;
}
.mapping_left_side_status_body_bottom_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 5px);
  height: 100%;
}
.mapping_left_side_status_body_bottom_content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: calc((100% - 50px) / 6);
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}

.mapping_left_side_status_body_bottom_content_title {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  color: $main_white;
}
.mapping_left_side_status_body_bottom_content_title.sensor {
  height: calc(100% / 3);
}
.mapping_left_side_status_body_bottom_content_sensor_state {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: red;
  border: 1px solid white;
}
.mapping_left_side_status_body_bottom_content_sensor_state.on {
  background: $main_color;
}
.mapping_left_side_status_body_bottom_content_value {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(1, 250, 254);
  font-size: 1.2rem;
  flex-wrap: wrap;
}
.mapping_left_side_status_body_bottom_content_value.sensor {
  height: calc(100% / 3);
}
#lte .mapping_left_side_status_body_bottom_content_value {
  background: url("@/../public/images/lte_1.png") no-repeat center;
  background-size: 20px 20px;
}
#lte .mapping_left_side_status_body_bottom_content_value.one {
  background: url("@/../public/images/lte_1.png") no-repeat center;
  background-size: 20px 20px;
}
#lte .mapping_left_side_status_body_bottom_content_value.two {
  background: url("@/../public/images/lte_2.png") no-repeat center;
  background-size: 20px 20px;
}
#lte .mapping_left_side_status_body_bottom_content_value.three {
  background: url("@/../public/images/lte_3.png") no-repeat center;
  background-size: 20px 20px;
}
#lte .mapping_left_side_status_body_bottom_content_value.four {
  background: url("@/../public/images/lte_4.png") no-repeat center;
  background-size: 20px 20px;
}
#rc .mapping_left_side_status_body_bottom_content_value {
  background: url("@/../public/images/rc_off.png") no-repeat center;
  background-size: contain;
}
#rc .mapping_left_side_status_body_bottom_content_value.on {
  background: url("@/../public/images/rc_on.png") no-repeat center;
  background-size: contain;
}
#warning .mapping_left_side_status_body_bottom_content_value {
  background: url("@/../public/images/warning_gray.png") no-repeat center;
  background-size: 25px 25px;
}
#warning .mapping_left_side_status_body_bottom_content_value.red {
  background: url("@/../public/images/warning_red.png") no-repeat center;
  background-size: 25px 25px;
}
#warning .mapping_left_side_status_body_bottom_content_value.orange {
  background: url("@/../public/images/warning_orange.png") no-repeat center;
  background-size: 25px 25px;
}
#warning .mapping_left_side_status_body_bottom_content_value.green {
  background: url("@/../public/images/warning_green.png") no-repeat center;
  background-size: 25px 25px;
}
.mapping_speed_value {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50%;
  width: 40%;
  font-size: 1.2rem;
  padding-bottom: 5px;
}
.mapping_speed_unit {
  display: flex;
  font-size: 1.2rem;
  align-items: flex-end;
  height: 50%;
  width: 60%;
  padding-bottom: 5px;
}
.mapping_fly_time {
  @include flexbox;
  padding-top: 5px;
  width: 100%;
  height: 50%;
  font-size: 1.2rem;
}
.mapping_left_side_status_body_bottom_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 5px);
  height: 100%;
}
.mapping_left_side_status_body_bottom_content_alt {
  width: 100%;
  height: calc((100% - 50px) / 6 * 3 + 20px);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 5px;
}
.mapping_alt_unit {
  @include flexbox;
  width: 100%;
  height: calc(100% / 8 - 5px);
  color: $main_white;
  font-size: 1.2rem;
}
.mapping_alt_value {
  @include flexbox;
  width: 100%;
  height: calc(100% / 8 - 5px);
  color: $main_color;
  font-size: 1.2rem;
}
.mapping_alt_img {
  position: relative;
  width: 100%;
  height: calc(100% / 4 * 3 + 10px);
  background: url("@/../public/images/alt.svg") no-repeat center;
  background-size: contain;
}
.mapping_alt_gage {
  position: absolute;
  bottom: 0px;
  left: calc(50% + 2px);
  width: 3px;
  height: 0%;
  background: $main_color;
}
</style>
