<template>
  <component :is="$route.meta.layout || 'main'">
    <div id="user_page">
      <section class="title_area">
        <h2>
          <img
            src="@/../public/images/users_gear_regular_main.svg"
            alt="user page icon"
          />
          <span>{{ $t("term.userManagement_Camel") }}</span>
        </h2>
        <h3>{{ $t("message.userManagement") }}</h3>
        <DefaultSearchInput
          :placeHolder="$t('placeholder.searchUserName')"
          :hasRemoveButton="true"
          @handleKeywordSearch="handleKeywordSearch($event)"
          @handleRemoveInputValue="handleRemoveInputValue"
        />
      </section>
      <UserListManager
        :total="filteredTotal"
        @handleOpenUserAddForm="handleOpenUserAddForm"
      >
        <template v-if="userListArray.length > 0">
          <template v-if="filteredUserListArray.length > 0">
            <UserList
              :listType="deviceType === 'mobile' ? 'card' : 'table'"
              :userList="filteredUserListArray"
              :currentPage="currentPage"
              @handleOpenUserEditForm="handleOpenUserEditForm($event)"
            />
            <Pagination
              class="pagination_margin"
              :totalCount="filteredTotal"
              @handleClickPaginationNumber="handleClickPaginationNumber($event)"
              :initialPageOnMount="currentPage"
              :itemsPerPage="itemsPerPage"
            />
          </template>
          <template v-else><NoSearchResult /></template>
        </template>
        <template v-else>
          <EmptyResult :title="$t('message.NoUsersRegistered')" />
        </template>
      </UserListManager>
      <CustomModalFrame v-if="isModalOpen" @closeModal="handleCloseCustomFrame">
        <UserAddFormContent
          v-if="modalName === 'add'"
          :uploadedImageUrl="uploadedImageUrl"
          @handleCloseCustomFrame="handleCloseCustomFrame"
          @handleUploadFile="handleUploadFile($event)"
          @handleAddUser="handleAddUser($event)"
          :userListArray="userListArray"
          @handleRemoveProfileImage="handleRemoveProfileImage"
        />
        <UserEditFormContent
          :editMode="'userInfo'"
          :uploadedImageUrl="uploadedImageUrl"
          :selectedUserInfo="selectedUserInfo"
          :userListArray="userListArray"
          :isDeleted="isDeleted"
          v-if="modalName === 'edit'"
          @handleCloseCustomFrame="handleCloseCustomFrame"
          @handleUploadFile="handleUploadFile($event)"
          @handleRemoveUser="handleRemoveUser($event)"
          @handleEditUser="handleEditUser($event)"
          @handleRemoveProfileImage="handleRemoveProfileImage"
        />
      </CustomModalFrame>
    </div>
  </component>
</template>
<script>
import * as RESTAPI from "@/shared/communication/RESTAPI";

import DefaultSearchInput from "@/component/input/DefaultSearchInput.vue";
import UserList from "./component/UserList.vue";
import UserListManager from "./component/UserListManager.vue";
import CustomModalFrame from "@/component/modal/custom/CustomModalFrame.vue";
import UserAddFormContent from "./component/UserAddFormContent.vue";
import UserEditFormContent from "./component/UserEditFormContent.vue";
import Pagination from "@/component/pagination/Pagination.vue";
import { paginateArray } from "@/shared/util/paginateArray";
import NoSearchResult from "@/component/no-result/NoSearchResult.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import EmptyResult from "@/component/no-result/EmptyResult.vue";

export default {
  name: "UserPage",
  components: {
    DefaultSearchInput,
    UserList,
    UserListManager,
    CustomModalFrame,
    UserAddFormContent,
    UserEditFormContent,
    Pagination,
    NoSearchResult,
    EmptyResult,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["deviceType"]),
  },
  data() {
    return {
      userListArray: [],
      filteredUserListArray: [],
      isModalOpen: false,
      modalName: "",
      selectedUserInfo: null,
      selectedPositionValue: null,
      itemsPerPage: 6,
      currentPage: 1,
      searchKeyword: "",
      total: 0,
      filteredTotal: 0,
      uploadedImageUrl: null,
      uploadedImageFile: null,
      isDeleted: false,
    };
  },
  methods: {
    /**
     * 유저 리스트 가져오기
     */
    async setUserList() {
      this.$store.commit("setIsLoading", true);

      const data = await RESTAPI.getUserList(this.user.affiliation.id);

      try {
        if (data.success) {
          this.$store.commit("setIsLoading", false);
          // 배열 최신순 부터 나올 수 있도록 정렬
          const reversedArray = data.result;

          // 배열에서 본인은 삭제
          // sb 관리자 계정은 애초에 서버에서 배열에서 제외시키고 보내줌
          const newDeleteMeArray = reversedArray.filter(
            (item) => item.id !== this.user.id
          );

          this.userListArray = newDeleteMeArray;

          this.total = newDeleteMeArray.length;
          this.filteredTotal = newDeleteMeArray.length;

          this.filteredUserListArray = paginateArray(
            newDeleteMeArray,
            this.itemsPerPage
          );

          return;
        } else {
          this.$store.commit("setIsLoading", false);
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.failedToLoadUserList")}\n${this.$t(
              "otherAlert.pleaseReloadOrRetry"
            )}`
          );
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("setIsLoading", false);
        return this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.failedToLoadUserList")}\n${this.$t(
            "otherAlert.pleaseReloadOrRetry"
          )}`
        );
      }
    },
    /**
     * 유저 리스트 초기화 할 때 사용하는 함수 (배열 새로 할당 및 페이지네이션 부여 / 모달 창 닫기 해야할 때 사용한다.)
     */
    resetUserList() {
      this.filteredUserListArray = paginateArray(
        this.userListArray,
        this.itemsPerPage
      );

      this.handleCloseCustomFrame();
      this.total = this.userListArray.length;
      this.filteredTotal = this.userListArray.length;
    },
    /**
     * 사용자 추가 모달 열기 기능
     */
    async handleOpenUserAddForm() {
      this.isModalOpen = true;
      this.modalName = "add";
    },
    /**
     * 사용자 수정 모달 열기 기능
     */
    async handleOpenUserEditForm(userInfo) {
      this.isModalOpen = true;
      this.modalName = "edit";
      this.selectedUserInfo = userInfo;
      this.$store.commit("setSelectedUserInfo", userInfo);
    },
    /**
     * 모달 닫기
     */
    handleCloseCustomFrame() {
      this.isModalOpen = false;
      this.modalName = "";
      this.$store.commit("setSelectedUserInfo", null);

      this.uploadedImageUrl = null;
      this.uploadedImageFile = null;
      this.isDeleted = false;
    },
    /**
     * 사용자 검색 기능
     */
    handleKeywordSearch(keyword) {
      this.searchKeyword = keyword;
      this.handleFilterArray();
    },
    handleFilterArray() {
      this.currentPage = 1;
      const keyword = this.searchKeyword
        ? this.searchKeyword.toLowerCase()
        : ""; // 검색어가 빈 문자열이면 빈 문자열 유지

      // 원본 데이터를 사용하여 필터링 (this.facilities)
      const filteredArray = this.userListArray.filter((user) => {
        // 조건이 null이거나 빈 문자열이면 해당 조건을 무시
        const matchesName = keyword
          ? user.fullName && user.fullName.toLowerCase().includes(keyword)
          : true; // keyword가 비어있으면 무시

        return matchesName;
      });

      this.filteredTotal = filteredArray.length;
      // 필터된 배열을 잘라서 this.filteredFacilities에 넣어줌
      this.filteredUserListArray = paginateArray(
        filteredArray,
        this.itemsPerPage
      );
    },
    /**
     * 프로필 이미지 파일 업로드
     */
    async handleUploadFile({ file, inputElement }) {
      // 동일한 파일을 업로드할 때, 디텍되지 않는 문제를 해결하기 위함.
      inputElement.value = "";

      let image = window.URL.createObjectURL(file);
      this.uploadedImageFile = file;
      this.uploadedImageUrl = image;
      this.isDeleted = false;
    },
    /**
     * 유저 추가 기능
     */
    async handleAddUser(userInfo) {
      this.$store.commit("setIsLoading", true);

      try {
        const userData = await RESTAPI.postUser(userInfo);

        console.log(userData);

        if (userData.status === 409) {
          if (userData.data.result.message.includes("해당 소속")) {
            return this.$store.commit(
              "openAlert",
              this.$t("otherAlert.maximumNumberOfSomethingExceeded", {
                something: this.$t("term.affiliation"),
              })
            );
          } else if (userData.data.result.message.includes("해당 권한")) {
            return this.$store.commit(
              "openAlert",
              this.$t("otherAlert.maximumNumberOfSomethingExceeded", {
                something: this.$t("term.admin"),
              })
            );
          }

          return this.$store.commit(
            "openAlert",
            this.$t("otherAlert.alreadyExistId")
          );
        }

        if (userData.status === 403) {
          return this.$store.commit(
            "openAlert",
            this.$t("otherAlert.onlyAdministratorCanAddUser")
          );
        }

        if (userData.status === 400) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.ErrorOccurredWithErrorMessage", {
              errorMessage: userData.data.error,
            })}\n${this.$t("otherAlert.pleaseReloadOrRetry")}`
          );
        }

        if (userData.success) {
          /**
           * ? : 이미지 파일이 존재한다면 formData로 전송
           */

          let newUserData = userData.result;

          if (this.uploadedImageFile || this.isDeleted) {
            const formData = new FormData();

            if (this.uploadedImageFile) {
              // 프로필 이미지 업로드하면 file object
              formData.append("userProfile", this.uploadedImageFile);
            } else if (this.isDeleted) {
              // 프로필 이미지 삭제하면 빈 값
              formData.append(
                "userProfile",
                new File([], "", { type: "text/plain" })
              );
            }

            const thumbnailData = await RESTAPI.postUserThumbnail(
              userData.result.id,
              formData
            );

            newUserData.profileImageUrl = thumbnailData.result.profileImageUrl;
          } else {
            newUserData.profileImageUrl = null;
          }

          this.userListArray = [newUserData, ...this.userListArray];

          this.resetUserList();
          this.handleCloseCustomFrame();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 유저 삭제
     */
    async handleRemoveUser(selectedUserInfo) {
      this.selectedUserInfo = selectedUserInfo;

      this.$store.commit("setIsLoading", true);
      try {
        const userData = await RESTAPI.deleteUser(
          this.user.id,
          this.selectedUserInfo.id
        );

        if (userData.status === 400) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.ErrorOccurredWithErrorMessage", {
              errorMessage: userData.data.error,
            })}\n${this.$t("otherAlert.pleaseReloadOrRetry")}`
          );
        }

        if (userData.success) {
          this.handleCloseCustomFrame();

          const removeUser = this.userListArray.find(
            (user) => user.id === this.selectedUserInfo.id
          );

          if (removeUser) {
            const newUsers = this.userListArray.filter(
              (user) => user.id !== this.selectedUserInfo.id
            );

            this.userListArray = newUsers;
            this.resetUserList();
            this.currentPage = 1;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 유저 수정
     */
    async handleEditUser(newUserInfo) {
      this.$store.commit("setIsLoading", true);

      try {
        const editData = await RESTAPI.patchUser(
          this.selectedUserInfo.id,
          newUserInfo
        );

        if (editData.status === 409) {
          return this.$store.commit(
            "openAlert",
            this.$t("otherAlert.alreadyExistId")
          );
        }

        if (editData.status === 403) {
          return this.$store.commit(
            "openAlert",
            this.$t("otherAlert.onlyAdministratorCanAddUser")
          );
        }

        if (editData.status === 415) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.ErrorOccurredWithErrorMessage", {
              errorMessage: editData.data.error,
            })}\n${this.$t("otherAlert.pleaseReloadOrRetry")}`
          );
        }
        if (editData.success) {
          /**
           * 유저 수정 성공한다면?
           */
          let newUserData = editData.result;

          if (this.uploadedImageFile || this.isDeleted) {
            const formData = new FormData();

            if (this.uploadedImageFile) {
              // 프로필 이미지 업로드하면 file object
              formData.append("userProfile", this.uploadedImageFile);
            } else if (this.isDeleted) {
              // 프로필 이미지 삭제하면 빈 값
              formData.append(
                "userProfile",
                new File([], "", { type: "text/plain" })
              );
            }

            const thumbnailData = await RESTAPI.postUserThumbnail(
              this.selectedUserInfo.id,
              formData
            );

            newUserData.profileImageUrl = thumbnailData.result.profileImageUrl;
          } else {
            newUserData.profileImageUrl = null;
          }

          this.setUserList();
          this.resetUserList();
          this.handleCloseCustomFrame();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 유저 프로필 이미지 삭제
     */
    handleRemoveProfileImage() {
      this.uploadedImageUrl = null;
      this.uploadedImageFile = "";
      this.isDeleted = true;
    },
    /**
     * 페이지 네이션 숫자 클릭 동작
     */
    handleClickPaginationNumber(pageNumber) {
      this.currentPage = pageNumber;
    },
    /**
     * 검색 인풋 value 값 삭제하거나 없을 때 동작
     */
    ...mapActions(["updateDeviceType"]),
    handleRemoveInputValue() {
      this.resetUserList();
    },
    /**
     * 윈도우 너비에 따른 디바이스 사이즈 디텍하기
     */
    handleResize() {
      this.updateDeviceType();
    },
  },
  mounted() {
    this.setUserList();
    this.handleResize(); // 초기 화면 사이즈 체크
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
@import "@/style/component/badge";
@import "@/style/component/formContent";
@import "@/style/component/inputs";

#user_page {
  padding-top: 71px;
  background: $main_black;
  min-height: 100vh;

  @include mobile {
    padding-top: 28px;
  }

  .empty_result_wrap {
    padding: 100px 0 0;
  }

  h2 {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 12px;
    @include flexbox(center, flex-start, row);
    gap: 12px;

    @include tablet {
      font-size: 2.8rem;
      margin-bottom: 8px;
    }

    @include mobile {
      font-size: 2.4rem;
    }

    img {
      font-size: 0;
      width: 34px;
      height: 34px;
      object-fit: contain;

      @include tablet {
        width: 30px;
        height: 30px;
      }
    }
  }

  h3 {
    font-size: 2.4rem;
    margin-bottom: 60px;

    @include tablet {
      font-size: 2rem;
      margin-bottom: 45px;
    }

    @include mobile {
      font-size: 1.6rem;
      margin-bottom: 25px;
    }
  }

  section {
    @include maxInner(1280px);
    color: $main_white;
  }

  .title_area {
    margin-top: 60px;
  }

  .pagination_margin {
    margin-top: 50px;
  }
}
</style>
