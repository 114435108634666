<template>
  <div v-if="isMissionListModalVisible" class="inspection_mission_modal">
    <div class="inspection_mission_modal_header">{{ $t("term.planList") }}</div>
    <div
      class="inspection_mission_modal_header_x"
      @click="$emit('closeModal')"
    ></div>
    <div class="inspection_mission_modal_content_wrap">
      <div
        class="inspection_mission_modal_content button"
        v-for="(mission, index) in filteredMissionList"
        :key="index"
        @click="loadingMission(mission)"
      >
        <div class="inspection_mission_modal_content_name">
          {{ mission.name }}
        </div>
        <div class="inspection_mission_modal_content_regdate">
          {{ mission.createdDatetime }}
        </div>
        <div
          class="inspection_mission_modal_content_x"
          @click.stop="deleteMission(mission)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "./InspectionMissionListModal.scss";

export default {
  props: {
    isMissionListModalVisible: Boolean,
    filteredMissionList: Array,
  },
  computed : {
    ...mapState(["user"]),
  },
  emits: ["closeModal", "loadingMission", "deleteMission"],
  methods: {
    loadingMission(mission) {
      this.$emit("loadingMission", mission);
    },
    deleteMission(mission) {
      if (confirm(this.$t("droneAlert.deleteMissionForSure"))) {
        if (this.user.role === "normal") {
          this.$store.commit(
            "openAlert",
            this.$t("otherAlert.unauthorizedPleaseContactAdmin")
          );
        } else {
          this.$emit("deleteMission", mission);
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
