<template>
  <div class="class_modal_background"></div>
  <div class="class_modal">
    <div class="class_modal_header">
      <div class="class_modal_title">{{ $t("term.selectClasses") }}</div>
      <div class="class_modal_x" @click="closeClassModal"></div>
    </div>
    <div class="class_modal_content">
      <div class="class_modal_button button" @click="changeDrawState('crack')">
        {{ $t("term.crack") }}
      </div>
      <div
        class="class_modal_button button"
        @click="changeDrawState('efflorescence')"
      >
        {{ $t("term.efflorescence") }}
      </div>
      <div
        class="class_modal_button button"
        @click="changeDrawState('spalling')"
      >
        {{ $t("term.delamination") }}
      </div>
      <div class="class_modal_button button" @click="changeDrawState('rebar')">
        {{ $t("term.rebar") }}
      </div>
      <div
        class="class_modal_button button"
        @click="changeDrawState('leakage')"
      >
        {{ $t("term.leakage") }}
      </div>
      <div
        class="class_modal_button button"
        @click="changeDrawState('net_crack')"
      >
        {{ $t("term.crazing") }}
      </div>
      <div class="class_modal_button button" @click="changeDrawState('etc')">
        {{ $t("term.etc") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["closeClassModal", "changeDrawState"],
  data() {
    return {
      type: null,
    };
  },
  methods: {
    closeClassModal() {
      this.$emit("closeClassModal");
    },
    changeDrawState(method) {
      this.$emit("changeDrawState", method);
      this.closeClassModal();
    },
  },
};
</script>

<style lang="scss">
.class_modal_background {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 21;
}
.class_modal {
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 250px);
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 330px;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  z-index: 22;
}
.class_modal_header {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 40px;
  padding: 0px 10px 0px 10px;
}
.class_modal_title {
  @include flexbox;
  width: calc(100% - 35px);
  height: 100%;
  color: $main_white;
  font-size: 2rem;
}
.class_modal_x {
  width: 25px;
  height: 100%;
  border-radius: 5px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 15px 15px;
  cursor: pointer;
}
.class_modal_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px;
}
.class_modal_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.4rem;
}
</style>
