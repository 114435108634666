<template>
  <component :is="$route.meta.layout || 'main'">
    <div class="main_fixed main korean">
      <ReportUploadModalVue
        ref="uploadModal"
        :uploadState="uploadState"
        @closeUploadModal="handleUploadModal(false)"
        :volume="volume"
        @clickUpload="checkStorage"
        @uploadAlbum="uploadAlbum"
      />
      <ReportPatternUploadModal
        :isUploadPatternModalOpen="isUploadPatternModalOpen"
        :selectedAlbumInfo="selectedAlbumInfo"
        :uploaded3DModelFile="uploaded3DModelFile"
        :isPatternUploading="isPatternUploading"
        @closeUploadPatternModal="handleUploadPatternModal(false)"
        @clickUpload3DModel="clickUpload3DModel"
        @handleUpdatePatternFile="handleUpdatePatternFile($event)"
      />
      <div
        class="cloud_viewer"
        ref="cloud_viewer"
        :class="{ detail: reportDetails }"
      ></div>
      <ReportLeftLayoutVue :reportDetails="reportDetails">
        <ReportMapListVue
          ref="mapList"
          @selectMap="selectMap"
          @deleteMap="deleteMap"
          @expandAlbumMenu="expandAlbumMenu"
          :filteredMapList="filteredMapList"
        />
        <ReportAlbumListVue
          ref="albumList"
          @openUploadModal="handleUploadModal(true)"
          @openUploadPatternModal="handleUploadPatternModal($event)"
          @selectAlbum="selectAlbum"
          @deleteAlbum="deleteAlbum"
          @downloadAlbum="downloadAlbum"
          @setSelectedAlbumInfo="setSelectedAlbumInfo($event)"
          :uploadState="uploadState"
          :isUploadPatternModalOpen="isUploadPatternModalOpen"
          @unSelectAlbum="unSelectAlbum"
          @expandMapMenu="expandMapMenu"
          :albumList="albumList"
          :selectedAlbumList="selectedAlbumList"
          @clickAnalysis="clickAnalysis"
          @handleClickDeletePatternMap="handleClickDeletePatternMap($event)"
          :ableToDelete3DModelFile="ableToDelete3DModelFile"
          :selectedAlbumInfo="selectedAlbumInfo"
        />
        <ReportHardVolumeVue :volume="volume"></ReportHardVolumeVue>
      </ReportLeftLayoutVue>
      <ReportInfoVue
        v-if="reportDetails"
        :selectedAlbumList="selectedAlbumList"
        :selectedPhoto="selectedPhoto"
        :selectedCamera="selectedCamera"
        :photoList="photoList"
      />
      <ReportBottomLayoutVue
        ref="reportBottomLayout"
        v-if="photoList.length != 0 && selectedAlbumList.length != 0"
        @changeReportVue="changeReportVue"
        @changeVisiblePhotoList="handleVisiblePhotoList"
        @clickPhoto="clickPhoto"
        @deletePhoto="deletePhoto"
        :uploadState="uploadState"
        :reportDetails="reportDetails"
        :selectedAlbumList="selectedAlbumList"
        :photoList="photoList"
        :selectedPhoto="selectedPhoto"
        :visiblePhotoList="visiblePhotoList"
        :segmentationList="segmentationList"
        :detectionList="detectionList"
      />
      <ReportDetailsVue
        ref="report_detail"
        @openReportEditor="handleOpenReportEditor"
        @changeImageState="handleImageState"
        @clickPhoto="clickPhoto"
        :reportDetails="reportDetails"
        :imageState="imageState"
        :photoList="photoList"
        :selectedPhoto="selectedPhoto"
      >
        <ReportDetailsInfoVue
          :selectedPhoto="selectedPhoto"
          :selectedCamera="selectedCamera"
        />
        <ReportDetailsCrackVue
          :infos="infos"
          :selectedPhoto="selectedPhoto"
          :selectedCamera="selectedCamera"
        />
      </ReportDetailsVue>
    </div>

    <ReportEditorVue
      ref="report_editor"
      v-if="selectedAlbumList.length != 0 && reportEditor"
      :drawState="drawState"
      :imageState="imageState"
      :photoList="photoList"
      :selectedPhoto="selectedPhoto"
      :selectedSegmentation="selectedSegmentation"
      @changeImageState="handleImageState"
      @clickPhoto="clickPhoto"
    >
      <template v-slot:editor-info>
        <ReportEditorInfoVue
          :selectedPhoto="selectedPhoto"
          :selectedCamera="selectedCamera"
        />
      </template>
      <template v-slot:editor-crack>
        <ReportEditorCrackVue
          :infos="infos"
          :drawState="drawState"
          :selectedCamera="selectedCamera"
          :selectedSegmentation="selectedSegmentation"
          @closeReportEditor="reportEditor = false"
          @changeDrawState="handleDrawState"
          @changeDrawModalState="handleDrawModalState"
          @deleteCrackAll="deleteCrackAll"
          @drawCrackAll="drawCrackAll"
          @deleteCrack="deleteCrack"
          @drawCrack="drawCrack"
          @analysisSave="analysisSave"
          @openClassModal="handleClassModal(true)"
          @cancelDraw="cancelDraw"
        />
      </template>
      <template v-slot:editor-modal>
        <ReportEditorDrawModalVue
          v-if="drawModalState"
          :drawModalState="drawModalState"
          @changeLineThickness="handleLineThickness"
          @saveBox="saveBox"
          @changeDrawModalState="handleDrawModalState"
          @cancelDraw="cancelDraw"
          @saveLine="saveLine"
        />
        <ReportEditorClassModalVue
          ref="report_class_modal"
          v-if="classModalState"
          @closeClassModal="handleClassModal(false)"
          @changeDrawState="handleDrawState"
        />
      </template>
    </ReportEditorVue>
    <MainLoadingVue ref="mainLoading" />
  </component>
</template>

<script>
import PCReportViewer from "@/module/PointCloud/PointCloudViewReport.js";
import Canvas from "@/module/Canvas.js";

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Album from "@/model/Album.js";
import Recognition from "@/model/Recognition.js";
import Segmentation from "@/model/Segmentation.js";
import Detection from "@/model/Detection.js";
import Photo from "@/model/Photo.js";
import Camera from "@/model/Camera.js";
import Info from "@/model/Info.js";
import Point from "@/model/Point.js";
import Model from "@/model/Model.js";
import StorageVolume from "@/model/StorageVolume.js";

import ReportLeftLayoutVue from "./ReportLeftLayout.vue";
import ReportInfoVue from "./ReportInfo.vue";
import ReportDetailsVue from "./ReportDetails.vue";
import ReportEditorVue from "./ReportEditor.vue";
import MainLoadingVue from "../Common/MainLoading.vue";
import ReportUploadModalVue from "./ReportUploadModal.vue";
import ReportBottomLayoutVue from "./ReportBottomLayout.vue";
import ReportMapListVue from "./ReportMapList.vue";
import ReportAlbumListVue from "./ReportAlbumList.vue";
import ReportHardVolumeVue from "./ReportHardVolume.vue";
import ReportDetailsInfoVue from "./ReportDetailsInfo.vue";
import ReportDetailsCrackVue from "./ReportDetailsCrack.vue";
import ReportEditorCrackVue from "./ReportEditorCrack.vue";
import ReportEditorInfoVue from "./ReportEditorInfo.vue";
import ReportEditorDrawModalVue from "./ReportEditorDrawModal.vue";
import ReportEditorClassModalVue from "./ReportEditorClassModal.vue";

import { mapState } from "vuex";
import { markRaw, nextTick } from "vue";
import { formatISO } from "date-fns";
import ReportPatternUploadModal from "./ReportPatternUploadModal.vue";

import * as RESTAPI from "@/shared/communication/RESTAPI";

export default {
  name: "ReportMain",
  data() {
    return {
      cloudViewer: null,
      reportDetails: false,
      reportEditor: false,
      imageState: false,
      uploadState: false,
      isUploadPatternModalOpen: false,
      drawState: null,
      drawModalState: false,
      classModalState: false,
      filteredMapList: [],
      albumList: [],
      selectedCamera: null,
      selectedAlbumList: [],
      photoList: [],
      selectedPhoto: null,
      visiblePhotoList: [],
      segmentationList: [],
      selectedSegmentation: null,
      detectionList: [],
      selectedDetection: null,
      infos: [],
      volume: new StorageVolume(),
      selectedAlbumInfo: null,
      facilityModelInfo: null,
      uploaded3DModelFile: null,
      ableToDelete3DModelFile: true,
      hasUploadedModelFile: false,
      isPatternUploading: false,
    };
  },
  components: {
    ReportLeftLayoutVue,
    ReportInfoVue,
    ReportDetailsVue,
    ReportEditorVue,
    ReportUploadModalVue,
    MainLoadingVue,
    ReportBottomLayoutVue,
    ReportMapListVue,
    ReportAlbumListVue,
    ReportDetailsInfoVue,
    ReportDetailsCrackVue,
    ReportEditorCrackVue,
    ReportEditorInfoVue,
    ReportEditorDrawModalVue,
    ReportEditorClassModalVue,
    ReportHardVolumeVue,
    ReportPatternUploadModal,
  },
  computed: {
    ...mapState([
      "user",
      "api",
      "apiIp",
      "apiPort",
      "selectedFacility",
      "selectedFacilityMap",
      "isMobile",
    ]),
  },
  watch: {
    selectedPhoto: {
      handler(photo) {
        if (photo) {
          let findedAlbum = this.selectedAlbumList.find(
            (album) => album.id == photo.photo.albumId
          );
          this.selectedCamera = findedAlbum.camera;
        }
      },
    },
  },
  methods: {
    changeReportVue() {
      if (this.selectedPhoto != null) {
        this.reportDetails = !this.reportDetails;
        this.$nextTick(() => {
          if (this.cloudViewer) {
            this.cloudViewer.onWindowResize();
          }
        });
      } else {
        this.$store.commit(
          "openAlert",
          `${this.$t("albumAlert.pleaseSelectImage")}\n${this.$t(
            "albumAlert.pleaseSelectImageFirstAndClickButton"
          )}`
        );
      }
    },
    handleUploadModal(boolean) {
      if (this.isPatternUploading) {
        // 균열망도 업로드 중인 경우
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.uploadInProgressPleaseProceedAfterCompleted")}` // 업로드 진행중이니까 다른 작업 ㄴㄴ
        );
        return;
      }

      this.uploadState = boolean;
      if (this.isUploadPatternModalOpen === true) {
        // 균열망도 업로드 모달 상태 true인데 앨범 업로드 클릭시 균열망도 업로드 모달 닫기
        this.isUploadPatternModalOpen = false;
      }
    },
    handleUploadPatternModal(boolean) {
      if (this.isPatternUploading) {
        // 균열망도 업로드 중인 경우
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.uploadInProgressPleaseProceedAfterCompleted")}` // 업로드 진행중이니까 다른 작업 ㄴㄴ
        );
        return;
      }

      this.isUploadPatternModalOpen = boolean;

      if (!boolean) {
        // 균열망도 모달 닫히면 데이터 초기화
        this.handleResetUploaded3DModelFile();
      }

      if (this.uploadState === true) {
        // 앨범 업로드 모달 상태 true인데 균열망도 업로드 클릭시 앨범 업로드 모달 닫기
        this.uploadState = false;
      }
    },
    handleResetUploaded3DModelFile() {
      // 업로드 된 3D 파일을 초기화 한다.
      this.uploaded3DModelFile = null;
    },
    handleUpdatePatternFile(file) {
      this.uploaded3DModelFile = file;
    },
    async clickUpload3DModel(data, reportUploadGage) {
      this.isPatternUploading = true;
      try {
        // 우선 JSON 데이터 활용해서 껍데기를 만들어 준다.
        const modelUploadResult = await RESTAPI.post3DModel(
          this.$route.params.facilityId,
          data
        );

        // formData 생성
        let formData = new FormData();
        formData.append("file", this.uploaded3DModelFile);

        if (modelUploadResult.data?.success) {
          // 3D 모델 업로드
          const modelFileUploadResult = await RESTAPI.importUpload3DModelFile(
            this.$route.params.facilityId,
            modelUploadResult.data.result.id,
            formData,
            reportUploadGage
          );

          console.log("modelFileUploadResult");
          console.log(modelFileUploadResult);

          if (
            !modelFileUploadResult.data ||
            !modelFileUploadResult.data?.success
          ) {
            if (modelFileUploadResult.response.status === 400) {
              // 파일 잘 못 올렸을 경우 에러 처리
              this.api.getModels(
                this.deleteModelCallback,
                modelUploadResult.data.result.facilityModel3DId
              );
              this.$store.commit(
                "openAlert",
                `${this.$t("albumAlert.folderOrFileIsMissing")}`
              );
            } else {
              this.api.getModels(
                this.deleteModelCallback,
                modelUploadResult.data.result.facilityModel3DId
              );
              this.$store.commit(
                "openAlert",
                `${this.$t("albumAlert.failedToUploadSomething", {
                  something: this.$t("term.crackPatternMap"),
                })}`
              );
            }

            // 오류 있을 경우,, 알럿 띄워주기
            this.isUploadPatternModalOpen = false;
            this.ableToDelete3DModelFile = false; // 삭제 불가능한 상태(업로드한 파일이 없는 상태)로 되돌리기
            this.handleResetUploaded3DModelFile(); // 업로드된 파일 초기화
            return;
          }

          if (modelFileUploadResult.data?.success) {
            console.log("this.albumList");
            console.log(this.albumList);
            // 여기서 업로드한 모델의 정보를 albumList 의 해당하는 아이템 안에 넣어준다.
            const matchingAlbum = this.albumList.find(
              (item) => item.id === modelFileUploadResult.data.result.albumId
            );

            if (matchingAlbum) {
              matchingAlbum.model = modelFileUploadResult.data.result;
            }

            this.isUploadPatternModalOpen = false; // 파일 업로드 끝까지 성공했을 시 모달 닫아주고
            this.ableToDelete3DModelFile = false; // 삭제 불가능한 상태(업로드한 파일이 없는 상태)로 되돌리기
            this.handleResetUploaded3DModelFile(); // 업로드된 파일 초기화
            this.$store.commit(
              "openAlert",
              `${this.$t("otherAlert.uploadCompletedSuccessfully")}` // 업로드 성공 알럿
            );
            return;
          }
        } else {
          // 애초에 껍데기 만들기를 실패했을 경우
          this.isUploadPatternModalOpen = false;
          this.$store.commit(
            "openAlert",
            `${this.$t("albumAlert.failedToUploadSomething", {
              something: this.$t("term.crackPatternMap"),
            })}`
          );
          return;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isPatternUploading = false;
      }
    },
    deleteModelCallback(dataObject) {
      /**
       * TODO : 이거 코드 정리해야함 !!!!!
       */
      const result = dataObject.data.data.result;
      const albumData = {
        model: {},
      };

      // albumList 배열을 순회하며 result Object와 albumId가 동일한 아이템을 찾는다.
      this.albumList.forEach((album) => {
        const matchingAlbum = result.find((item) => item.albumId === album.id);
        if (matchingAlbum) {
          albumData.model.id = matchingAlbum.id;
          albumData.model.facilityModel3DId = dataObject.facilityModelId;
        }
      });

      this.handleClickDeletePatternMap(albumData);
    },
    async handleClickDeletePatternMap(albumData) {
      this.$store.commit("setIsLoading", true);
      try {
        const facilityModel3DId = albumData?.model?.facilityModel3DId;
        const roiPartsId = albumData?.model?.id;
        const deleteResult = await RESTAPI.delete3DModel(
          facilityModel3DId,
          roiPartsId
        );

        if (!deleteResult.data || !deleteResult.data.success) {
          // 삭제 실패했을 경우
          this.ableToDelete3DModelFile = true;
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.pleaseReloadOrRetry")}`
          );
        }

        this.selectedAlbumInfo.model = null;
        this.ableToDelete3DModelFile = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    handleImageState(boolean) {
      if (boolean != undefined) {
        this.imageState = boolean;
      } else {
        this.imageState = !this.imageState;
      }
    },
    handleDrawState(method) {
      this.drawState = method;
      if (method) {
        if (method == "crack") {
          this.canvas.setImageEventLine();
        } else {
          this.$refs.report_editor.setPan(false);
          this.canvas.setImageEventBox(method);
        }
      } else {
        this.$refs.report_editor.setPan(true);
        this.canvas.deleteImageEvent();
      }
    },
    handleDrawModalState(string) {
      this.drawModalState = string;
    },
    getStorageCallback(data) {
      this.volume = new StorageVolume(data.data.result);
    },
    getSite() {
      if (
        !this.selectedFacility ||
        this.selectedFacility.id != this.$route.params.facilityId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.facilityId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedFacility", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.facilityId);
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result?.length != 0) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.selectedFacility.mapList = mapList;
        this.filteredMapList = mapList
          .filter((item) => item.url !== "")
          .reverse();
        if (this.filteredMapList.length != 0) {
          if (!this.selectedFacilityMap) {
            this.$store.commit(
              "setSelectedFacilityMap",
              this.filteredMapList[0]
            );
          }
          this.cloudViewer.loadPCD(this.selectedFacilityMap.url);
          this.cloudViewer.createDroneModel();
          this.readAlbumList(this.selectedFacilityMap);
        } else {
          this.$router.push({ name: "home" });
        }
      }
    },
    selectMap(map) {
      this.cloudViewer.disposeScene();
      if (map) {
        this.$store.commit("setSelectedFacilityMap", map);
        this.cloudViewer.loadPCD(this.selectedFacilityMap.url);
        this.cloudViewer.createDroneModel();
      } else {
        this.$store.commit("setSelectedFacilityMap", null);
      }
      this.readAlbumList(this.selectedFacilityMap);
    },
    readAlbumList(map) {
      if (map) {
        this.api.getAlbums(
          this.getAlbumsCallback,
          this.$route.params.facilityId,
          map.id
        );
      }
    },
    getAlbumsCallback(data) {
      this.albumList = [];
      this.selectedAlbumList = [];
      this.photoList = [];
      this.selectedPhoto = null;
      this.cloudViewer.deleteAllCameraAngle();
      if(this.reportDetails){
        this.reportDetails = false;
          this.$nextTick(() => {
            if (this.cloudViewer) {
              this.cloudViewer.onWindowResize();
            }
        })
      }
      let result = data.data.result;
      if (result.length != 0) {
        for (let i = 0; i < result.length; i++) {
          this.albumList.push(new Album(result[i]));
          this.api.getCrackRecognitions(
            this.getCrackRecognitionsCallback,
            result[i].id,
            i
          );
          this.api.getOtherRecognitions(
            this.getOtherRecognitionsCallback,
            result[i].id,
            i
          );
        }
        // this.api.getFacilityModels(
        //   this.getFacilityModelsCallback,
        //   this.$route.params.facilityId
        // );
      }
    },
    getFacilityModelsCallback(data) {
      let result = data.data.result;
      if (result) {
        this.facilityModel = data.data.result[0];
        this.facilityModelInfo = data.data.result[0];
        this.api.getModels(this.getModelsCallback, this.facilityModel.id);
      }
    },
    getCrackRecognitionsCallback(data, albumIndex) {
      let result = data.data.result;
      if (result) {
        let crackRecognition = result.find(
          (crackRecognition) => crackRecognition.name == "crack"
        );
        if (crackRecognition) {
          this.albumList[albumIndex].crackRecognition = new Recognition(
            crackRecognition
          );
        } else {
          this.albumList[albumIndex].crackRecognition = null;
        }
      }
    },
    getOtherRecognitionsCallback(data, albumIndex) {
      let result = data.data.result;
      if (result) {
        let otherRecognition = result.find(
          (otherRecognition) => otherRecognition.name == "others"
        );
        if (otherRecognition) {
          this.albumList[albumIndex].otherRecognition = new Recognition(
            otherRecognition
          );
        } else {
          this.albumList[albumIndex].otherRecognition = null;
        }
      }
    },
    getModelsCallback(data) {
      let result = data.data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let model = new Model(result[i]);
          let albumIndex = this.albumList.findIndex(
            (album) => album.id === model.albumId
          );
          if (albumIndex != -1) {
            this.albumList[albumIndex].model = model;
          }
        }
      }
    },
    selectAlbum(album) {
      this.$store.commit("setIsLoading", true);
      this.selectedAlbumList.push(album);
      this.api.getCamera(this.getCameraCallback, album);
      this.getAnalyses(album);
    },
    setSelectedAlbumInfo(album) {
      this.selectedAlbumInfo = album;
    },
    unSelectAlbum(album) {
      let index = this.selectedAlbumList.findIndex(
        (selectedAlbum) => selectedAlbum.id == album.id
      );
      this.selectedAlbumList.splice(index, 1);
      if (album.crackRecognition) {
        this.segmentationList = this.segmentationList.filter(
          (segmentation) =>
            segmentation.albumCrackRecognitionId != album.crackRecognition.id
        );
      }
      if (album.otherRecognition) {
        this.detectionList = this.detectionList.filter(
          (detection) =>
            detection.albumOtherRecognitionId != album.otherRecognition.id
        );
      }
      this.photoList = this.photoList.filter(
        (photo) => photo.photo.albumId != album.id
      );
      this.cloudViewer.deleteCameraAngle(album);
      if (this.selectedPhoto) {
        if (album.id == this.selectedPhoto.photo.albumId) {
          this.clickPhoto(null);
        }
      }
    },
    expandAlbumMenu() {
      this.$refs.albumList.expandAlbumMenu(null);
    },
    expandMapMenu() {
      this.$refs.mapList.expandMapMenu(null);
    },
    checkStorage(albumBody, file) {
      this.api.getStorage(this.postAlbums, this.user.id, albumBody, file);
    },
    postAlbums(data, albumBody, file) {
      this.volume = new StorageVolume(data.data.result);
      if (this.volume.maxStorageSize - this.volume.currentUsage < file.size) {
        this.$refs.uploadModal.upload_process = false;
        this.$store.commit(
          "openAlert",
          this.$t("albumAlert.unableToUploadExceedStorageFile")
        );
      } else {
        this.api.postAlbums(this.postAlbumsCallback, albumBody);
      }
    },
    postAlbumsCallback(data) {
      let result = data.data.result;
      this.$refs.uploadModal.postAlbumsCallback(result);
    },
    clickAnalysis(album) {
      if (!album.process) {
        album.process = true;
        if (album.crackRecognition) {
          let data = {
            data: {
              result: album.crackRecognition,
            },
          };
          this.postCrackRecognitionsCallback(data);
        } else {
          this.postAnalysis(album);
        }
      }
    },
    postAnalysis(album) {
      let body = {
        albumId: album.id,
        name: "crack",
        tableName: "",
        type: 1,
        typeName: "Segmentation",
        status: "Running",
        createdDatetime: formatISO(new Date()),
      };
      this.api.postCrackRecognitions(
        this.postCrackRecognitionsCallback,
        album.id,
        body
      );
    },
    postCrackRecognitionsCallback(data) {
      let result = data.data.result;
      var socket = new WebSocket(
        `wss://www.sirius-ex.co.kr:${this.apiPort}/ws/albums/${this.user.id}/analyses`
      );
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            analysisId: result.id,
          })
        );
        this.readAlbumList(this.selectedFacilityMap);
        console.log(`Analysis Web Socket Open`);
      };
      socket.onclose = () => {
        console.log(`Analysis Web Socket Close`);
      };
      socket.onmessage = (event) => {
        if (event.data.includes(`[오류]`)) {
          if (event.data.includes(`컴퓨터 리소스`)) {
            this.$store.commit(
              "openAlert",
              this.$t("albumAlert.AllComputerResourcesAreInUse")
            );
          } else if (event.data.includes(`인공지능 분석중 오류`)) {
            this.$store.commit(
              "openAlert",
              this.$t("albumAlert.errorOccurredDuringAnalysis")
            );
          } else {
            this.$store.commit("openAlert", event.data);
          }
          this.readAlbumList(this.selectedFacilityMap);
          socket.close();
        } else if (event.data.includes(`[완료]`)) {
          this.$store.commit(
            "openAlert",
            this.$t("albumAlert.analysisCompleted")
          );
          this.readAlbumList(this.selectedFacilityMap);
          socket.close();
        }
      };
    },
    getAnalyses(selectedAlbum) {
      if (selectedAlbum) {
        if (
          selectedAlbum.id &&
          selectedAlbum.crackRecognition &&
          selectedAlbum.otherRecognition
        ) {
          this.api.getSegmentations(
            this.getSegmentationsCallback,
            selectedAlbum
          );
        } else {
          this.readsPhotos(selectedAlbum);
        }
      }
    },
    getSegmentationsCallback(data, album) {
      let result = data.data.result;
      let crackCount = 0;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let segmentation = new Segmentation(result[i]);
          this.segmentationList.push(segmentation);
          crackCount += result[i].crackCount;
        }
        let findedAlbum = this.selectedAlbumList.find(
          (selectedAlbum) => selectedAlbum.id == album.id
        );
        findedAlbum.crackCount = crackCount;
      }
      this.api.getDetections(this.getDetectionsCallback, album);
    },
    getDetectionsCallback(data, album) {
      let result = data.data.result;
      let otherCount = 0;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let detection = new Detection(result[i]);
          this.detectionList.push(detection);
          otherCount += result[i].otherCount;
        }
        let findedAlbum = this.selectedAlbumList.find(
          (selectedAlbum) => selectedAlbum.id == album.id
        );
        findedAlbum.otherCount = otherCount;
      }
      this.readsPhotos(album);
    },
    selectAnalyses(photo) {
      this.infos = [];
      let segmentation = this.segmentationList.find(
        (segmentation) => segmentation.photoId == photo.photo.id
      );
      let detection = this.detectionList.find(
        (detection) => detection.photoId == photo.photo.id
      );

      if (segmentation && detection) {
        this.selectedSegmentation = segmentation;
        this.selectedDetection = detection;
        this.getCrackInfos(this.selectedSegmentation);
      } else {
        this.selectedSegmentation = null;
        this.selectedDetection = null;
        this.canvas.imgLoad(
          this.selectedPhoto.photo.albumalbumPhotoResizedPathhotoPath,
          this.infos,
          null
        );
        if (this.reportEditor) {
          this.canvas.editImgLoad(
            this.selectedPhoto.photo.albumPhotoPath,
            this.infos
          );
        }
      }
    },
    getCrackInfos(selectedSegmentation) {
      this.api.getCrackInfos(this.getCrackInfosCallback, selectedSegmentation);
    },
    getCrackInfosCallback(data, selectedSegmentation) {
      let result = data.data;
      if (result) {
        result = result.sort((a, b) => a.mask_id - b.mask_id);
        for (let i = 0; i < result.length; i++) {
          let info = new Info(result[i]);
          info.type = "crack";
          this.infos.push(info);
        }
      }
      this.api.getCrackPoints(
        this.getCrackPointsCallback,
        selectedSegmentation
      );
    },
    getCrackPointsCallback(data) {
      let result = data.data;
      if (result) {
        result = result.sort((a, b) => a.mask_id - b.mask_id);
        for (let i = 0; i < result.length; i++) {
          let info = this.infos.find(
            (info) => info.mask_id == result[i].mask_id
          );
          if (info) {
            info.point = new Point(result[i]);
          }
        }
      }
      this.api.getOtherInfos(
        this.getOtherInfosCallback,
        this.selectedDetection.urisOfOthers
      );
    },
    getOtherInfosCallback(data, index) {
      let result = data.data;
      switch (index) {
        case 0:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "efflorescence";
              this.infos.push(info);
            }
          }
          break;
        case 1:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "spalling";
              this.infos.push(info);
            }
          }
          break;
        case 2:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "rebar";
              this.infos.push(info);
            }
          }
          break;
        case 3:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "leakage";
              this.infos.push(info);
            }
          }
          break;
        case 4:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "net_crack";
              this.infos.push(info);
            }
          }
          break;
        case 5:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "etc";
              this.infos.push(info);
            }
          }
          break;
        default:
          break;
      }
      this.canvas.imgLoad(
        this.selectedPhoto.photo.albumalbumPhotoResizedPathhotoPath,
        this.infos,
        this.selectedCamera
      );
      if (this.reportEditor) {
        this.canvas.editImgLoad(
          this.selectedPhoto.photo.albumPhotoPath,
          this.infos
        );
      }
    },
    readsPhotos(album) {
      if (album) {
        this.api.getPhotos(this.getPhotosCallback, album.id);
      }
    },
    getPhotosCallback(data) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let photo = new Photo(result[i]);
          this.photoList.push(photo);
          let segmentation = this.segmentationList.find(
            (segmentation) => segmentation.photoId == photo.photo.id
          );
          if (segmentation) {
            this.cloudViewer.createCameraAngle(photo, segmentation);
          } else {
            this.cloudViewer.createCameraAngle(photo, null);
          }
          nextTick(() => {
            if (this.$refs.reportBottomLayout)
              this.$refs.reportBottomLayout.photoUpdate();
          });
        }
      }
      this.$store.commit("setIsLoading", false);
    },
    clickPhoto(photo) {
      this.selectPhoto(photo);
      if (photo) {
        this.selectAnalyses(photo);
      }
    },
    selectPhoto(photo) {
      if (photo) {
        if (this.selectedPhoto) {
          let prior_index = this.photoList.findIndex(
            (photo) => photo.id == this.selectedPhoto.id
          );
          this.cloudViewer.cancelCameraAngle(prior_index);
        }
        this.selectedPhoto = photo;
        let index = this.photoList.findIndex(
          (photo) => photo.id == this.selectedPhoto.id
        );
        this.cloudViewer.selectCameraAngle(index);
      } else {
        this.selectedPhoto = null;
      }
    },
    getCameraCallback(data, album) {
      let findedAlbum = this.selectedAlbumList.find(
        (selectedAlbum) => selectedAlbum.id == album.id
      );
      if (findedAlbum) findedAlbum.camera = new Camera(data.data.result);
    },
    deletePhoto(photo) {
      if (confirm(this.$t("albumAlert.reallyDeletePhoto"))) {
        this.api.deletePhoto(this.deletePhotoCallback, photo);
      } else {
        return false;
      }
    },
    deletePhotoCallback(data, photo) {
      if (data.data.success) {
        let segmentationIndex = this.segmentationList.findIndex(
          (segmentation) => segmentation.photoId == photo.photo.id
        );
        let detectionIndex = this.detectionList.findIndex(
          (detection) => detection.photoId == photo.photo.id
        );
        let findedAlbum = this.albumList.find(
          (ablum) => ablum.id == photo.photo.albumId
        );
        if (segmentationIndex != -1) {
          findedAlbum.crackCount -=
            this.segmentationList[segmentationIndex].crackCount;
          this.segmentationList.splice(segmentationIndex, 1);
        }
        if (detectionIndex != -1) {
          findedAlbum.otherCount -=
            this.detectionList[detectionIndex].otherCount;
          this.detectionList.splice(detectionIndex, 1);
        }
        let photoIndex = this.photoList.findIndex(
          (findedPhoto) => findedPhoto.id == photo.id
        );
        this.photoList.splice(photoIndex, 1);
        if (this.photoList.length == 0) {
          this.reportDetails = false;
          this.$nextTick(() => {
            if (this.cloudViewer) {
              this.cloudViewer.onWindowResize();
            }
          });
        } else {
          if (this.selectedPhoto && this.selectedPhoto.id == photo.id) {
            this.clickPhoto(null);
            if (this.photoList[photoIndex]) {
              this.clickPhoto(this.photoList[photoIndex]);
            } else {
              this.clickPhoto(this.photoList[photoIndex - 1]);
            }
          }
        }
      }
    },
    handleOpenReportEditor() {
      this.reportEditor = true;
      this.canvas.editImgLoad(
        this.selectedPhoto.photo.albumPhotoPath,
        this.infos
      );
    },
    handleVisiblePhotoList(index) {
      if (!this.visiblePhotoList.includes(index)) {
        this.visiblePhotoList.push(index);
      }
    },
    deleteMap(mapId) {
      this.api.deleteMap(
        this.mapDeleteCallback,
        this.$route.params.facilityId,
        mapId
      );
    },
    mapDeleteCallback() {
      this.getMaps();
      this.selectMap(null);
    },
    downloadAlbum(albumId) {
      this.api.downloadAlbum(this.downloadAlbumCallback, albumId);
    },
    downloadAlbumCallback(data) {
      this.$store.commit("setDownloading", true);
      let fileUrl = data.data.result.message;
      let link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "album.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$store.commit("setDownloading", false);
    },
    uploadAlbum(albumId, formData, progressGage) {
      this.api.uploadAlbum(
        this.uploadAlbumCallback,
        albumId,
        formData,
        progressGage
      );
    },
    uploadAlbumCallback(success, albumId) {
      this.$refs.uploadModal.upload_process = false;
      this.$refs.uploadModal.closeUploadModal();
      if (success) {
        this.readAlbumList(this.selectedFacilityMap);
        this.$store.commit(
          "openAlert",
          this.$t("albumAlert.photoUploadCompleted")
        );
      } else {
        this.$store.commit(
          "openAlert",
          `${this.$t("albumAlert.photoUploadFailed")}\n${this.$t(
            "albumAlert.pleaseContactAdmin"
          )}`
        );
        this.deleteAlbum(albumId);
      }
      this.api.getStorage(this.getStorageCallback, this.user.id);
    },
    deleteAlbum(albumId) {
      this.api.deleteAlbum(this.deleteAlbumCallback, albumId);
    },
    deleteAlbumCallback() {
      this.readAlbumList(this.selectedFacilityMap);
    },
    deleteCrack(index) {
      if (this.infos[index].type == "crack") {
        this.canvas.deleteCrack(1, 1, "edit", index);
        this.canvas.setImgData("edit");
      }
    },
    deleteCrackAll() {
      this.canvas.deleteCrackAll(1, 1, "edit");
      this.canvas.setImgData("edit");
    },
    drawCrack(index) {
      if (this.infos[index].type == "crack") {
        this.canvas.drawCrack(1, 1, "edit", index);
        this.canvas.setImgData("edit");
      }
    },
    drawCrackAll() {
      this.canvas.drawCrackAll(1, 1, "edit");
      this.canvas.setImgData("edit");
    },
    handleClassModal(boolean) {
      if (boolean) {
        this.handleImageState(false);
      }
      this.classModalState = boolean;
    },
    handleLineThickness(thickness) {
      this.canvas.drawLine(thickness);
    },
    cancelDraw() {
      this.canvas.setImgData("edit");
    },
    saveLine() {
      this.canvas.saveLine();
    },
    saveBox(method) {
      this.canvas.saveBox(method);
    },
    analysisSave(indexArray) {
      let findedAlbum = this.selectedAlbumList.find(
        (selectedAlbum) =>
          selectedAlbum.crackRecognition.id ==
          this.selectedSegmentation.albumCrackRecognitionId
      );
      let { tempCrackCount, tempOtherCount } = this.infos.reduce(
        (count, info) => {
          if (info.type == "crack") {
            count.tempCrackCount += 1;
          } else {
            count.tempOtherCount += 1;
          }
          return count;
        },
        { tempCrackCount: 0, tempOtherCount: 0 }
      );
      findedAlbum.crackCount -= tempCrackCount;
      findedAlbum.otherCount -= tempOtherCount;

      // 체크 해제된 것들만 모여있는 배열 indexArray를 파라미터로 보낸다.
      this.canvas.saveJson(indexArray, this.analysisSaveCallback);
    },
    analysisSaveCallback(infos) {
      this.$store.commit("setIsLoading", true);
      let findedAlbum = this.selectedAlbumList.find(
        (selectedAlbum) =>
          selectedAlbum.crackRecognition.id ==
          this.selectedSegmentation.albumCrackRecognitionId
      );
      let { tempCrackCount, tempOtherCount } = this.infos.reduce(
        (count, info) => {
          if (info.type == "crack") {
            count.tempCrackCount += 1;
          } else {
            count.tempOtherCount += 1;
          }
          return count;
        },
        { tempCrackCount: 0, tempOtherCount: 0 }
      );
      findedAlbum.crackCount += tempCrackCount;
      this.selectedSegmentation.crackCount = tempCrackCount;
      findedAlbum.otherCount += tempOtherCount;
      this.selectedDetection.otherCount = tempOtherCount;
      let photoIndex = this.photoList.findIndex(
        (photo) => photo.id == this.selectedPhoto.id
      );
      let crackInfos = [];
      let crackPoints = [];
      let count = 1;
      for (let i = 0; i < infos.length; i++) {
        if (infos[i].type === "crack") {
          let infoData = {
            box: infos[i].box,
            crack_length: infos[i].crack_length,
            crack_width: infos[i].crack_width,
            mask_id: count,
            ...(infos[i].cross_point_cloud && {
              cross_point_cloud: infos[i].cross_point_cloud,
            }),
            ...(infos[i].distance && { distance: infos[i].distance }),
          };

          let pointData = {
            mask_id: count,
            points: infos[i].point.points,
          };

          crackInfos.push(infoData);
          crackPoints.push(pointData);
          count++;
        }
      }
      if (crackInfos.length != 0) {
        this.cloudViewer.updateCameraAngleCrack(photoIndex, true);
      } else {
        this.cloudViewer.updateCameraAngleCrack(photoIndex, false);
      }
      this.api.putSegmentations(
        this.putSegmentationsCallback,
        findedAlbum,
        this.selectedSegmentation,
        crackInfos,
        crackPoints
      );
    },
    async putSegmentationsCallback(album) {
      let efflorescenceInfo = [];
      let spallingInfo = [];
      let rebarInfo = [];
      let leakageInfo = [];
      let net_crackInfo = [];
      let etcInfo = [];

      let efflorescenceCount = 1;
      let spallingCount = 1;
      let rebarCount = 1;
      let leakageCount = 1;
      let net_crackCount = 1;
      let etcCount = 1;
      for (let i = 0; i < this.infos.length; i++) {
        if (this.infos[i].type != "crack") {
          let infoData = {
            box: this.infos[i].box,
            crack_length: this.infos[i].crack_length,
            crack_width: this.infos[i].crack_width,
            ...(this.infos[i].cross_point_cloud && {
              cross_point_cloud: this.infos[i].cross_point_cloud,
            }),
            ...(this.infos[i].distance && { distance: this.infos[i].distance }),
          };
          switch (this.infos[i].type) {
            case "efflorescence":
              infoData.mask_id = efflorescenceCount;
              efflorescenceInfo.push(infoData);
              efflorescenceCount++;
              break;
            case "spalling":
              infoData.mask_id = spallingCount;
              spallingInfo.push(infoData);
              spallingCount++;
              break;
            case "rebar":
              infoData.mask_id = rebarCount;
              rebarInfo.push(infoData);
              rebarCount++;
              break;
            case "leakage":
              infoData.mask_id = leakageCount;
              leakageInfo.push(infoData);
              leakageCount++;
              break;
            case "net_crack":
              infoData.mask_id = net_crackCount;
              net_crackInfo.push(infoData);
              net_crackCount++;
              break;
            case "etc":
              infoData.mask_id = etcCount;
              etcInfo.push(infoData);
              etcCount++;
              break;
            default:
              break;
          }
        }
      }

      this.api.putDetections(
        this.putDetectionsCallback,
        album,
        this.selectedDetection,
        JSON.stringify([
          efflorescenceInfo,
          spallingInfo,
          rebarInfo,
          leakageInfo,
          net_crackInfo,
          etcInfo,
        ])
      );
    },
    putDetectionsCallback() {
      this.$store.commit("setIsLoading", false);
      this.clickPhoto(this.selectedPhoto);
    },
    cleanUp() {
      if (this.cloudViewer) {
        this.cloudViewer.dispose();
        this.cloudViewer = null;
      }
    },
  },
  created() {
    this.$store.dispatch("checkDeviceType");
  },
  mounted() {
    this.cloudViewer = markRaw(
      new PCReportViewer(this.$refs.cloud_viewer, this.$refs.mainLoading)
    );
    this.canvas = new Canvas(this.apiIp, this.apiPort);
    this.api.putUser({ taskStatus: "reporting" }, this.user.id);
    this.getSite();
    this.api.getStorage(this.getStorageCallback, this.user.id);
    if (!this.isMobile) {
      this.$refs.cloud_viewer.addEventListener("click", (event) => {
        if (this.cloudViewer) {
          let index = this.cloudViewer.raycaster(event);
          if (index) {
            this.clickPhoto(this.photoList[index]);
          }
        }
      });
    }
  },
  beforeUnmount() {
    this.cleanUp();
  },
};
</script>

<style lang="scss">
.cloud_viewer {
  position: relative;
  width: 100%;
  height: 100%;
}
.cloud_viewer.detail {
  position: relative;
  width: calc(100% - 520px);
  height: 100%;
}
@media (max-width: 1300px) {
  .cloud_viewer.detail {
    position: relative;
    width: 60%;
    height: 100%;
  }
}
.report_bottom_content_img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
</style>
