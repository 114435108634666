<template>
  <div v-if="isVisible" class="inspection_plan_modal_overlay">
    <div class="inspection_plan_title">{{ $t("term.flightPlan") }}</div>
    <div
      class="inspection_plan_menu"
      @click="planLoadingClicked"
      :class="{ on: isMissionListModalVisible }"
    >
      {{ $t("term.flightPlanLoading") }}
    </div>
    <div
      class="inspection_plan_menu"
      :class="{ on: isPlanningClicked }"
      @click="planningClicked"
    >
      {{ $t("term.flightPlanCreateEdit") }}
      <div
        class="inspection_plan_menu_extand"
        :class="{ on: isPlanningClicked }"
      ></div>
    </div>
    <div
      class="inspection_plan_menu"
      @click="planSaveClicked"
      :class="{ on: isMissionSaveModalVisible }"
    >
      {{ $t("term.flightPlanSave") }}
    </div>
    <div class="inspection_plan_title" :style="{ marginTop: '20px' }">
      {{ $t("term.inspectionControl") }}
    </div>
    <div class="inspection_plan_menu" @click="armClicked">
      {{
        droneData[currentDroneId].state.arming
          ? this.$t("term.turnOffEngine")
          : this.$t("term.turnOnEngine")
      }}
    </div>
    <div class="inspection_plan_menu" @click="takeoffClicked">
      {{
        droneData[currentDroneId].state.takeOff
          ? this.$t("term.landing")
          : this.$t("term.takeoff")
      }}
    </div>
    <div
      class="inspection_plan_menu"
      :class="{ on: droneData[currentDroneId].state.rtl }"
      @click="rtlClicked"
    >
      RTL
    </div>
    <div
      class="inspection_plan_menu"
      :class="{ on: droneData[currentDroneId].state.rth }"
      @click="rthClicked"
    >
      RTH
    </div>
    <div class="inspection_plan_menu" @click="controlChangeClicked">
      {{ $t("term.changeFlightMode") }}
    </div>
    <div class="inspection_plan_title" :style="{ marginTop: '20px' }">
      {{ $t("term.automaticInspection") }}
    </div>
    <div class="inspection_plan_menu" @click="startClicked">
      {{
        droneData[currentDroneId].state.mission
          ? $t("term.pause")
          : $t("term.start")
      }}
    </div>
    <div class="inspection_plan_menu" @click="resetClicked">
      {{ $t("term.reset") }}
    </div>
  </div>

  <div v-if="isPlanningClicked" class="inspection_planning_container">
    <div class="inspection_plan_menu" @click="planRTLClicked">RTL</div>
    <div class="inspection_plan_menu" @click="planRTHClicked">RTH</div>
    <div class="inspection_plan_menu" @click="fitClicked('Waypoint')">
      {{ $t("term.waypoint") }}
    </div>
    <div class="inspection_plan_menu" @click="fitClicked('Line')">
      {{ $t("term.automaticPathCreate") }}
    </div>
    <div class="inspection_plan_menu" @click="editFitGroupClicked">
      {{ $t("term.groupEdit") }}
    </div>
  </div>

  <div class="inspection_area_fit_mode_wrap" v-if="fitShape">
    <div
      v-if="fitShape == 'Waypoint'"
      class="inspection_area_fit_mode_no_hover button"
    >
      Waypoint
    </div>
    <div
      v-else
      class="inspection_area_fit_mode button"
      @click="isParamsContainer = !isParamsContainer"
    >
      {{ $t("term.automaticPathCreate") }}
    </div>
    <div
      v-if="fitShape != 'Waypoint' && fitShape != null"
      class="inspection_area_fit_complete button"
      @click="fitApplyClicked"
    >
      {{ $t("button.create") }}
    </div>
    <div
      class="inspection_area_fit_complete button"
      @click="fitCompleteClicked"
    >
      {{ $t("button.done") }}
    </div>
  </div>

  <div
    v-if="isAreaSearchType && fitShape && isParamsContainer"
    class="area_search_type_selector"
  >
    <div
      v-if="fitShape != 'Line'"
      class="area_search_type_selector_content"
      @click="fitClicked('Line')"
    >
      {{ $t("term.line") }}
    </div>
    <div
      v-if="fitShape != 'Underside'"
      class="area_search_type_selector_content"
      @click="fitClicked('Underside')"
    >
      {{ $t("term.underSide") }}
    </div>
    <div
      v-if="fitShape != 'Circle'"
      class="area_search_type_selector_content"
      @click="fitClicked('Circle')"
    >
      {{ $t("term.circle") }}
    </div>
    <div
      v-if="fitShape != 'Rectangle'"
      class="area_search_type_selector_content"
      @click="fitClicked('Rectangle')"
    >
      {{ $t("term.rectangle") }}
    </div>
  </div>

  <div
    v-if="fitShape == 'Line' && isParamsContainer"
    class="inspection_area_fit_params_container"
  >
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param" id="area_fit_type">
        {{ $t("term.type") }}
      </div>
      <div
        class="inspection_area_fit_type_select"
        @click="isAreaSearchType = !isAreaSearchType"
      >
        {{ getShapeText(fitShape) }}
      </div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">
        {{ $t("term.heightInterval") }}[m]
      </div>
      <input
        :placeholder="lineParams.heightInterval.toString()"
        class="inspection_area_fit_input"
        @blur="setLineHeightInterval"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">{{ $t("term.height") }}[m]</div>
      <input
        :placeholder="lineParams.height.toString()"
        class="inspection_area_fit_input"
        @blur="setLineHeight"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">
        {{ $t("term.separationDistance") }}[m]
      </div>
      <input
        :placeholder="lineParams.fromWall.toString()"
        class="inspection_area_fit_input"
        @blur="setLineFromWall"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">
        {{ $t("term.numberOfPoints") }}
      </div>
      <input
        :placeholder="lineParams.nFitPoints.toString()"
        class="inspection_area_fit_input"
        @blur="setLineNFitPoints"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">{{ $t("term.gimbalAngle") }}</div>
      <input
        :placeholder="lineParams.gimbalPitch.toString()"
        class="inspection_area_fit_input"
        @blur="setLineGimbalPitch"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">{{ $t("term.direction") }}</div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newLineParams.direction == 'left' }"
        @click="setLineDirection('left')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.left") }}</div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newLineParams.direction == 'right' }"
        @click="setLineDirection('right')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.right") }}</div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newLineParams.direction == 'up' }"
        @click="setLineDirection('up')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.up") }}</div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newLineParams.direction == 'down' }"
        @click="setLineDirection('down')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.down") }}</div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">
        {{ $t("term.autoAndManual") }}
      </div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newLineParams.isAuto }"
        @click="setLineAuto(true)"
      ></div>
      <div class="inspection_plan_radio_discript">
        {{ $t("term.automatic") }}
      </div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: !newLineParams.isAuto }"
        @click="setLineAuto(false)"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.manual") }}</div>
    </div>
  </div>

  <div
    v-else-if="fitShape == 'Underside' && isParamsContainer"
    class="inspection_area_fit_params_container"
    :style="{ height: '275px' }"
  >
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param" id="area_fit_type">
        {{ $t("term.type") }}
      </div>
      <div
        class="inspection_area_fit_type_select"
        @click="isAreaSearchType = !isAreaSearchType"
      >
        {{ getShapeText(fitShape) }}
      </div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">{{ $t("term.interval") }}[m]</div>
      <input
        :placeholder="undersideParams.interval.toString()"
        class="inspection_area_fit_input"
        @blur="setUndersideInterval"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">
        {{ $t("term.separationDistance") }}[m]
      </div>
      <input
        :placeholder="undersideParams.fromWall.toString()"
        class="inspection_area_fit_input"
        @blur="setUndersideFromWall"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">
        {{ $t("term.numberOfPoints") }}
      </div>
      <input
        :placeholder="undersideParams.nBoxPoints.toString()"
        class="inspection_area_fit_input"
        @blur="setUndersideNBoxPoints"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">{{ $t("term.gimbalAngle") }}</div>
      <input
        :placeholder="undersideParams.gimbalPitch.toString()"
        class="inspection_area_fit_input"
        @blur="setUndersideGimbalPitch"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">{{ $t("term.direction") }}</div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newUndersideParams.direction == 'short' }"
        @click="setUndersideDirection('short')"
      ></div>
      <div class="inspection_plan_radio_discript">
        {{ $t("term.shortDirection") }}
      </div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newUndersideParams.direction == 'long' }"
        @click="setUndersideDirection('long')"
      ></div>
      <div class="inspection_plan_radio_discript">
        {{ $t("term.longDirection") }}
      </div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">
        {{ $t("term.autoAndManual") }}
      </div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newUndersideParams.isAuto }"
        @click="setUndersideAuto(true)"
      ></div>
      <div class="inspection_plan_radio_discript">
        {{ $t("term.automatic") }}
      </div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: !newUndersideParams.isAuto }"
        @click="setUndersideAuto(false)"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.manual") }}</div>
    </div>
  </div>

  <div
    v-else-if="fitShape == 'Circle' && isParamsContainer"
    class="inspection_area_fit_params_container"
    :style="{ height: '250px' }"
  >
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param" id="area_fit_type">
        {{ $t("term.type") }}
      </div>
      <div
        class="inspection_area_fit_type_select"
        @click="isAreaSearchType = !isAreaSearchType"
      >
        {{ getShapeText(fitShape) }}
      </div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">
        {{ $t("term.interval") }}[&deg;]
      </div>
      <input
        :placeholder="circleParams.interval.toString()"
        class="inspection_area_fit_input"
        @blur="setCircleInterval"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">
        {{ $t("term.startAngle") }}[&deg;]
      </div>
      <input
        :placeholder="circleParams.startAngle.toString()"
        class="inspection_area_fit_input"
        @blur="setCircleStartAngle"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">
        {{ $t("term.separationDistance") }}[[m]
      </div>
      <input
        :placeholder="circleParams.fromWall.toString()"
        class="inspection_area_fit_input"
        @blur="setCircleFromWall"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">{{ $t("term.direction") }}</div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newCircleParams.direction == 'inward' }"
        @click="setCircleDirection('inward')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.inside") }}</div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newCircleParams.direction == 'outward' }"
        @click="setCircleDirection('outward')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.outside") }}</div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">
        {{ $t("term.upperLowerPhotograph") }}
      </div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newCircleParams.gimbalControl }"
        @click="setCircleGimbalControl(true)"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.yes") }}</div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: !newCircleParams.gimbalControl }"
        @click="setCircleGimbalControl(false)"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.no") }}</div>
    </div>
  </div>

  <div
    v-else-if="fitShape == 'Rectangle' && isParamsContainer"
    class="inspection_area_fit_params_container"
    :style="{ height: '225px' }"
  >
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param" id="area_fit_type">
        {{ $t("term.type") }}
      </div>
      <div
        class="inspection_area_fit_type_select"
        @click="isAreaSearchType = !isAreaSearchType"
      >
        {{ getShapeText(fitShape) }}
      </div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">{{ $t("term.interval") }}[m]</div>
      <input
        :placeholder="rectangleParams.interval.toString()"
        class="inspection_area_fit_input"
        @blur="setRectangleInterval"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param">
        {{ $t("term.separationDistance") }}[m]
      </div>
      <input
        :placeholder="rectangleParams.fromWall.toString()"
        class="inspection_area_fit_input"
        @blur="setRectangleFromWall"
        @input="validateNumber($event, 3)"
      />
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">{{ $t("term.direction") }}</div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newRectangleParams.direction == 'inward' }"
        @click="setRectDirection('inward')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.inside") }}</div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newRectangleParams.direction == 'outward' }"
        @click="setRectDirection('outward')"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.outside") }}</div>
    </div>
    <div
      class="inspection_area_fit_params_wrapper"
      :style="{ marginTop: '10px' }"
    >
      <div class="inspection_area_fit_param">
        {{ $t("term.upperLowerPhotograph") }}
      </div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: newRectangleParams.gimbalControl }"
        @click="setRectGimbalControl(true)"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.yes") }}</div>
    </div>
    <div class="inspection_area_fit_params_wrapper">
      <div class="inspection_area_fit_param"></div>
      <div
        class="inspection_plan_radio_button radio"
        :class="{ on: !newRectangleParams.gimbalControl }"
        @click="setRectGimbalControl(false)"
      ></div>
      <div class="inspection_plan_radio_discript">{{ $t("term.no") }}</div>
    </div>
  </div>

  <div v-if="fitShape" class="inspection_area_height_controller">
    <div class="inspection_area_height_arrows">
      <div
        class="inspection_area_height_arrow_up_img"
        @click="setHeight(currentHeight + 1)"
      ></div>
      <div class="vertical_line_box"><div class="vertical_line"></div></div>
      <div
        class="inspection_area_height_arrow_down_img"
        @click="setHeight(currentHeight - 1)"
      ></div>
    </div>
    <div class="inspection_area_currnet_height">
      {{ $t("term.height") }} &nbsp;&nbsp; {{ currentHeight }}
    </div>
    <div class="inspection_area_height_area">
      <input
        placeholder="0"
        class="inspection_area_height_area_value"
        :value="lowerHeight"
        @blur="setLowerHeight"
        @input="validateNumber($event, 3)"
      />
      <div
        :style="{
          width: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        ~
      </div>
      <input
        placeholder="0"
        class="inspection_area_height_area_value"
        :value="upperHeight"
        @blur="setUpperHeight"
        @input="validateNumber($event, 3)"
      />
    </div>
  </div>

  <div
    v-if="isVisible && !editFitGroupMode"
    class="inspection_waypoint_table_container"
    :style="{ bottom: isMonitorVisible ? '10px' : '50px' }"
  >
    <div class="inspection_waypoint_head">
      <div class="inspection_waypoint_head_item"></div>
      <div class="inspection_waypoint_head_item">{{ $t("term.order") }}</div>
      <div class="inspection_waypoint_head_item" :style="{ width: '50px' }">
        X
      </div>
      <div class="inspection_waypoint_head_item" :style="{ width: '50px' }">
        Y
      </div>
      <div class="inspection_waypoint_head_item" :style="{ width: '50px' }">
        Z
      </div>
      <div class="inspection_waypoint_head_item" :style="{ width: '50px' }">
        YAW
      </div>
    </div>
    <div class="inspection_waypoint_item_container">
      <div
        v-for="(waypoint, idx) in waypointList[currentDroneId]"
        :key="idx"
        :ref="`waypoint${idx}`"
        class="inspection_waypoint_item_wrapper"
        :class="{
          on: droneData[currentDroneId].state.waypointNumber - 1 == idx,
        }"
        @click="changeWaypointNumber(idx)"
      >
        <div class="inspection_waypoint_item">
          <div
            v-if="waypoint.getChecked()"
            class="inspection_waypoint_item_checkbox inspection_waypoint_item_checked"
            @click.stop="waypointCheck(idx, false)"
          ></div>
          <div
            v-else
            class="inspection_waypoint_item_checkbox"
            @click.stop="waypointCheck(idx, true)"
          ></div>
        </div>
        <div class="inspection_waypoint_item">{{ idx + 1 }}</div>
        <div class="inspection_waypoint_item" :style="{ width: '50px' }">
          {{ waypoint.getX().toFixed(1) }}
        </div>
        <div class="inspection_waypoint_item" :style="{ width: '50px' }">
          {{ waypoint.getY().toFixed(1) }}
        </div>
        <div class="inspection_waypoint_item" :style="{ width: '50px' }">
          {{ waypoint.getZ().toFixed(1) }}
        </div>
        <div class="inspection_waypoint_item" :style="{ width: '50px' }">
          {{ waypoint.getYawDegree().toFixed(1) }}
        </div>
      </div>
    </div>
    <div class="inspection_waypoint_table_button_wrapper">
      <div
        class="inspection_waypoint_table_button button"
        @click="deleteWaypoint"
      >
        {{ $t("button.delete") }}
      </div>
      <div
        class="inspection_waypoint_table_button button"
        :style="{ marginLeft: '10px' }"
        @click="clearWaypoint"
      >
        {{ $t("button.clear") }}
      </div>
    </div>
    <div class="inspection_waypoint_table_button_wrapper">
      <div
        class="inspection_waypoint_table_collision_button button"
        @click="collisionCheckClicked"
      >
        {{ $t("button.crashCheck") }}
      </div>
    </div>
  </div>

  <div
    v-if="editFitGroupMode"
    class="inspection_waypoint_table_container"
    :style="{ height: '270px' }"
  >
    <div class="inspection_waypoint_head">
      <div class="inspection_waypoint_head_item"></div>
      <div class="inspection_waypoint_head_item" :style="{ width: '50px' }">
        {{ $t("term.order") }}
      </div>
      <div class="inspection_waypoint_head_item" :style="{ width: '180px' }">
        {{ $t("term.type") }}
      </div>
    </div>
    <div class="inspection_waypoint_item_container">
      <div
        v-for="(group, idx) in groupList[currentDroneId]"
        :key="idx"
        :data-idx="idx"
        class="inspection_waypoint_item_wrapper"
        :style="getGroupTableStyle(idx)"
        @mousedown="handleGroupTableEvent(idx, $event)"
        @mouseup="handleGroupTableEvent(idx, $event)"
        @mousemove="handleGroupTableEvent(idx, $event)"
        @touchstart="handleGroupTableEvent(idx, $event)"
        @touchend="handleGroupTableEvent(idx, $event)"
        @touchmove="handleGroupTableEvent(idx, $event)"
      >
        <div class="inspection_waypoint_item">
          <div
            :class="{
              inspection_waypoint_item_checkbox: true,
              inspection_waypoint_item_checked: group.getChecked(),
            }"
            @click.stop="groupCheck(idx, !group.getChecked())"
          ></div>
        </div>
        <div class="inspection_waypoint_item" :style="{ width: '50px' }">
          {{ group.getGroupNum() }}
        </div>
        <div class="inspection_waypoint_item" :style="{ width: '180px' }">
          {{
            group.getShape() === "Merged Circle"
              ? "Merged Circle"
              : group.getShape() === "Merged Rectangle"
              ? "Merged Rectangle"
              : group.getShape()
          }}
        </div>
      </div>
    </div>
    <div class="inspection_waypoint_table_button_wrapper">
      <div
        class="inspection_waypoint_table_button button"
        :style="{ width: 'calc(260px/3)' }"
        @click="rotateGroup"
      >
        {{ $t("button.rotate") }}
      </div>
      <div
        class="inspection_waypoint_table_button button"
        :style="{ marginLeft: '10px', width: 'calc(260px/3)' }"
        @click="mergeGroup"
      >
        {{ $t("button.merge") }}
      </div>
      <div
        class="inspection_waypoint_table_button button"
        :style="{ marginLeft: '10px', width: 'calc(260px/3)' }"
        @click="deleteGroup"
      >
        {{ $t("button.delete") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "./InspectionPlanMenu.scss";

export default {
  props: {
    isVisible: Boolean,
    isMonitorVisible: Boolean,
    currentDroneId: Number,
    waypointList: Array,
    groupList: Array,
    lineParams: Object,
    undersideParams: Object,
    circleParams: Object,
    rectangleParams: Object,
    lowerHeight: Number,
    upperHeight: Number,
    droneControl: Array,
    droneData: Array,
    droneParams: Object,
    fitShape: String,
    isMissionListModalVisible: Boolean,
    isMissionSaveModalVisible: Boolean,
  },
  emits: [
    "close",
    "fitShapeSelected",
    "fitApplyButtonClicked",
    "deleteWaypoint",
    "clearWaypoint",
    "deleteGroup",
    "rotateGroup",
    "mergeGroup",
    "swapGroups",
    "checkCollision",
    "updateWaypoint",
    "heightChanged",
    "lineParamsChanged",
    "undersideParamsChanged",
    "circleParamsChanged",
    "rectangleParamsChanged",
    "lineNFitPointsChanged",
    "undersideNBoxPointsChanged",
    "lowerHeightChanged",
    "upperHeightChanged",
    "missionStateChange",
    "planRTLClicked",
    "planRTHClicked",
    "changeArmPosition",
    "resetClicked",
    "openMissionListModal",
    "openMissionSaveModel",
    "clickRtl",
    "clickRth",
    "changeWaypointNumber",
  ],
  computed: {
    ...mapState(["user"]),
    currentDroneWaypoint() {
      return this.droneData[this.currentDroneId]?.state.waypointNumber;
    },
  },
  data() {
    return {
      isAreaSearchType: true,
      isPlanningClicked: false,
      isParamsContainer: false,
      editFitGroupMode: false,
      underDirection: "long",
      underAuto: "long",
      circleDirection: "inward",
      rectDirection: "inward",
      currentHeight: 0,
      fitGroups: [
        { groupNum: 1, droneId: 0, shape: "Circle", checked: false },
        { groupNum: 1, droneId: 1, shape: "Rectangle", checked: true },
        { groupNum: 3, droneId: 2, shape: "Underside", checked: false },
      ],
      wpColors: ["#01FAFE", "#01FFA9", "#1465F5", "#7B20ED", "#5A5A8D"],
      newLineParams: { ...this.lineParams },
      newUndersideParams: { ...this.undersideParams },
      newCircleParams: { ...this.circleParams },
      newRectangleParams: { ...this.rectangleParams },
      isMouseDown: false,
      activeIdx: null,
      hoveredIdx: null,
    };
  },
  methods: {
    checkInputLimit(event, limit) {
      if (event.target.value.length > limit) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNowAllowed", {
            limit: limit,
          })}`
        );
        event.target.value = null;
        event.target.blur();
      }
    },
    validateNumber(event, limit) {
      let regex = /[^\d.-]/g;
      if (regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(regex, "");
      }
      this.checkInputLimit(event, limit);
    },
    getShapeText(fitShape) {
      switch (fitShape) {
        case "Line":
          return this.$t("term.line");
        case "Underside":
          return this.$t("term.underSide");
        case "Circle":
          return this.$t("term.circle");
        case "Rectangle":
          return this.$t("term.rectangle");
        default:
          return false;
      }
    },
    planLoadingClicked() {
      this.isPlanningClicked = false;
      this.setFalseFit();
      this.$emit("openMissionListModal");
    },
    planningClicked() {
      this.isPlanningClicked = !this.isPlanningClicked;
      this.setFalseFit();
    },
    planSaveClicked() {
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        this.isPlanningClicked = false;
        this.setFalseFit();
        this.$emit("openMissionSaveModel");
      }
    },
    armClicked() {
      this.isPlanningClicked = false;
      this.setFalseFit();
      if (this.droneControl[this.currentDroneId]) {
        if (this.droneData[this.currentDroneId].state.arming) {
          this.droneControl[this.currentDroneId].disArm();
        } else {
          if (
            this.droneData[this.currentDroneId].battery >
            this.droneParams.batteryFailSafe
          ) {
            this.droneControl[this.currentDroneId].failsafe(
              this.droneParams.batteryFailSafe,
              this.droneParams.takeoffHeight
            );
            this.droneControl[this.currentDroneId].arm();
          } else {
            this.$store.commit(
              "openAlert",
              `${this.$t("droneAlert.moduleLowerBattery", {
                droneId: this.currentDroneId + 1,
              })}\n${this.$t("droneAlert.checkBatteryState")}`
            );
          }
        }
      } else {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.pleaseSetDroneTypeAndId", {
            droneId: this.currentDroneId + 1,
          })
        );
      }
    },
    takeoffClicked() {
      this.isPlanningClicked = false;
      this.setFalseFit();
      if (this.droneControl[this.currentDroneId]) {
        if (this.droneData[this.currentDroneId].state.arming) {
          if (this.droneData[this.currentDroneId].state.takeOff) {
            this.droneControl[this.currentDroneId].land();
          } else {
            if (
              this.droneData[this.currentDroneId].battery >
              this.droneParams.batteryFailSafe
            ) {
              this.$emit("changeArmPosition");
              this.droneControl[this.currentDroneId].takeOff(
                this.droneParams.takeoffHeight
              );
            } else {
              this.$store.commit(
                "openAlert",
                `${this.$t("droneAlert.moduleLowerBattery", {
                  droneId: this.currentDroneId + 1,
                })}\n${this.$t("droneAlert.checkBatteryState")}`
              );
            }
          }
        } else {
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.droneEngineIsTurnedOff", {
              droneId: this.currentDroneId + 1,
            })}\n${this.$t("droneAlert.turnOnEngineTakeoffLanding")}`
          );
        }
      } else {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.pleaseSetDroneTypeAndId", {
            droneId: this.currentDroneId + 1,
          })
        );
      }
    },
    rtlClicked() {
      if (
        this.droneData[this.currentDroneId].state.rtl ||
        this.droneData[this.currentDroneId].state.rth
      ) {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.droneAlreadyRTLorRTHMode", {
            droneId: this.currentDroneId + 1,
          })
        );
      } else {
        this.isPlanningClicked = false;
        this.setFalseFit();
        if (this.droneControl[this.currentDroneId]) {
          if (this.droneData[this.currentDroneId].state.takeOff) {
            if (this.droneData[this.currentDroneId].state.mission) {
              this.missionStop();
              this.$emit("missionStateChange", false);
            }
            setTimeout(() => {
              this.droneControl[this.currentDroneId].setWaypoint(
                this.droneData[this.currentDroneId].armPosition.x,
                this.droneData[this.currentDroneId].armPosition.y,
                this.droneParams.takeoffHeight,
                this.droneData[this.currentDroneId].armPosition.yaw,
                0,
                "rtl"
              );
            }, 2000);
            this.$emit("clickRtl");
          } else {
            this.$store.commit(
              "openAlert",
              `${this.$t("droneAlert.particularDroneIsNotFlying", {
                droneId: this.currentDroneId + 1,
              })}\n${this.$t("droneAlert.unableToUseMode", { mode: "RTL" })}`
            );
          }
        } else {
          this.$store.commit(
            "openAlert",
            this.$t("droneAlert.pleaseSetDroneTypeAndId", {
              droneId: this.currentDroneId + 1,
            })
          );
        }
      }
    },
    rthClicked() {
      if (
        this.droneData[this.currentDroneId].state.rtl ||
        this.droneData[this.currentDroneId].state.rth
      ) {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.droneAlreadyRTLorRTHMode", {
            droneId: this.currentDroneId + 1,
          })
        );
      } else {
        this.isPlanningClicked = false;
        this.setFalseFit();
        if (this.droneControl[this.currentDroneId]) {
          if (this.droneData[this.currentDroneId].state.takeOff) {
            if (this.droneData[this.currentDroneId].state.mission) {
              this.missionStop();
              this.$emit("missionStateChange", false);
            }
            setTimeout(() => {
              this.droneControl[this.currentDroneId].setWaypoint(
                0,
                0,
                this.droneParams.takeoffHeight,
                this.droneData[this.currentDroneId].armPosition.yaw,
                0,
                "rth"
              );
            }, 2000);
            this.$emit("clickRth");
          } else {
            this.$store.commit(
              "openAlert",
              `${this.$t("droneAlert.particularDroneIsNotFlying", {
                droneId: this.currentDroneId + 1,
              })}\n${this.$t("droneAlert.unableToUseMode", { mode: "RTH" })}`
            );
          }
        } else {
          this.$store.commit(
            "openAlert",
            this.$t("droneAlert.pleaseSetDroneTypeAndId", {
              droneId: this.currentDroneId + 1,
            })
          );
        }
      }
    },
    controlChangeClicked() {
      this.isPlanningClicked = false;
      this.setFalseFit();
      if (this.droneControl[this.currentDroneId]) {
        if (this.droneData[this.currentDroneId].flightMode == "GUIDED") {
          this.droneControl[this.currentDroneId].setModeLoiter();
        } else {
          this.droneControl[this.currentDroneId].setModeGuide();
        }
      } else {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.pleaseSetDroneTypeAndId", {
            droneId: this.currentDroneId + 1,
          })
        );
      }
    },
    startClicked() {
      this.isPlanningClicked = false;
      this.setFalseFit();
      if (this.droneControl[this.currentDroneId]) {
        if (this.droneData[this.currentDroneId].state.takeOff) {
          if (!this.droneData[this.currentDroneId].state.mission) {
            this.droneControl[this.currentDroneId].missionStart(
              this.droneData[this.currentDroneId].state.waypointNumber
            );
            this.$emit("missionStateChange", true);
          } else {
            this.missionStop();
            this.$emit("missionStateChange", false);
          }
        } else {
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.particularDroneIsNotFlying", {
              droneId: this.currentDroneId + 1,
            })}\n${this.$t("droneAlert.unalbeToStartTheMission")}`
          );
        }
      } else {
        this.$store.commit(
          "openAlert",

          this.$t("droneAlert.pleaseSetDroneTypeAndId", {
            droneId: this.currentDroneId + 1,
          })
        );
      }
    },
    missionStop() {
      this.droneControl[this.currentDroneId].stop();
    },
    resetClicked() {
      this.isPlanningClicked = false;
      this.setFalseFit();
      if (this.droneControl[this.currentDroneId]) {
        if (this.droneData[this.currentDroneId].state.mission) {
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.droneIsRunningMission", {
              droneId: this.currentDroneId + 1,
            })}\n${this.$t("droneAlert.pauseMissionTryAgain")}`
          );
        } else {
          if (confirm(this.$t("droneAlert.wannaResetTheMission"))) {
            this.$emit("resetClicked");
          } else {
            return false;
          }
        }
      } else {
        this.$store.commit(
          "openAlert",

          this.$t("droneAlert.pleaseSetDroneTypeAndId", {
            droneId: this.currentDroneId + 1,
          })
        );
      }
    },
    planRTLClicked() {
      this.isPlanningClicked = false;
      this.$emit("planRTLClicked");
    },
    planRTHClicked() {
      this.isPlanningClicked = false;
      this.$emit("planRTHClicked");
    },
    editFitGroupClicked() {
      this.editFitGroupMode = true;
      this.isPlanningClicked = false;
    },
    collisionCheckClicked() {
      this.$store.commit("setIsLoading", true);
      this.isPlanningClicked = false;
      this.$emit("checkCollision");
    },
    fitClicked(shape) {
      this.isPlanningClicked = false;
      this.isAreaSearchType = false;
      this.$emit("fitShapeSelected", shape);
    },
    fitApplyClicked() {
      this.$store.commit("setIsLoading", true);
      this.isAreaSearchType = false;
      this.$emit("fitApplyButtonClicked");
    },
    fitCompleteClicked() {
      this.isParamsContainer = false;
      this.$emit("fitShapeSelected", null);
    },
    setFalseFit() {
      this.editFitGroupMode = false;
      this.$emit("fitShapeSelected", null);
    },
    setLineHeightInterval(event) {
      if (event.target.value) {
        this.newLineParams.heightInterval = parseFloat(event.target.value);
        this.$emit("lineParamsChanged", this.newLineParams);
        event.target.value = null;
      }
    },
    setLineHeight(event) {
      if (event.target.value) {
        this.newLineParams.height = parseFloat(event.target.value);
        this.$emit("lineParamsChanged", this.newLineParams);
        event.target.value = null;
      }
    },
    setLineFromWall(event) {
      if (event.target.value) {
        this.newLineParams.fromWall = parseFloat(event.target.value);
        this.$emit("lineParamsChanged", this.newLineParams);
        event.target.value = null;
      }
    },
    setLineNFitPoints(event) {
      if (event.target.value) {
        this.newLineParams.nFitPoints = parseInt(event.target.value);
        this.$emit("lineNFitPointsChanged", this.newLineParams);
        event.target.value = null;
      }
    },
    setLineGimbalPitch(event) {
      if (event.target.value) {
        this.newLineParams.gimbalPitch = parseInt(event.target.value);
        this.$emit("lineParamsChanged", this.newLineParams);
        event.target.value = null;
      }
    },
    setLineDirection(direction) {
      this.newLineParams.direction = direction;
      this.$emit("lineParamsChanged", this.newLineParams);
    },
    setLineAuto(auto) {
      this.newLineParams.isAuto = auto;
      this.$emit("lineParamsChanged", this.newLineParams);
    },
    setUndersideInterval(event) {
      if (event.target.value) {
        this.newUndersideParams.interval = parseFloat(event.target.value);
        this.$emit("undersideParamsChanged", this.newUndersideParams);
        event.target.value = null;
      }
    },
    setUndersideFromWall(event) {
      if (event.target.value) {
        this.newUndersideParams.fromWall = parseFloat(event.target.value);
        this.$emit("undersideParamsChanged", this.newUndersideParams);
        event.target.value = null;
      }
    },
    setUndersideNBoxPoints(event) {
      if (event.target.value) {
        this.newUndersideParams.nBoxPoints = parseInt(event.target.value);
        this.$emit("undersideNBoxPointsChanged", this.newUndersideParams);
        event.target.value = null;
      }
    },
    setUndersideGimbalPitch(event) {
      if (event.target.value) {
        this.newUndersideParams.gimbalPitch = parseInt(event.target.value);
        this.$emit("undersideParamsChanged", this.newUndersideParams);
        event.target.value = null;
      }
    },
    setUndersideDirection(direction) {
      this.newUndersideParams.direction = direction;
      this.$emit("undersideParamsChanged", this.newUndersideParams);
    },
    setUndersideAuto(auto) {
      this.newUndersideParams.isAuto = auto;
      this.$emit("undersideParamsChanged", this.newUndersideParams);
    },
    setCircleInterval(event) {
      if (event.target.value) {
        this.newCircleParams.interval = parseFloat(event.target.value);
        this.$emit("circleParamsChanged", this.newCircleParams);
        event.target.value = null;
      }
    },
    setCircleStartAngle(event) {
      if (event.target.value) {
        this.newCircleParams.startAngle = parseFloat(event.target.value);
        this.$emit("circleParamsChanged", this.newCircleParams);
        event.target.value = null;
      }
    },
    setCircleFromWall(event) {
      if (event.target.value) {
        this.newCircleParams.fromWall = parseFloat(event.target.value);
        this.$emit("circleParamsChanged", this.newCircleParams);
        event.target.value = null;
      }
    },
    setCircleDirection(direction) {
      this.newCircleParams.direction = direction;
      this.$emit("circleParamsChanged", this.newCircleParams);
    },
    setCircleGimbalControl(boolean) {
      this.newCircleParams.gimbalControl = boolean;
      this.$emit("circleParamsChanged", this.newCircleParams);
    },
    setRectangleInterval(event) {
      if (event.target.value) {
        this.newRectangleParams.interval = parseFloat(event.target.value);
        this.$emit("rectangleParamsChanged", this.newRectangleParams);
        event.target.value = null;
      }
    },
    setRectangleFromWall(event) {
      if (event.target.value) {
        this.newRectangleParams.fromWall = parseFloat(event.target.value);
        this.$emit("rectangleParamsChanged", this.newRectangleParams);
        event.target.value = null;
      }
    },
    setRectDirection(direction) {
      this.newRectangleParams.direction = direction;
      this.$emit("rectangleParamsChanged", this.newRectangleParams);
    },
    setRectGimbalControl(boolean) {
      this.newRectangleParams.gimbalControl = boolean;
      this.$emit("rectangleParamsChanged", this.newRectangleParams);
    },
    waypointCheck(idx, checked) {
      this.waypointList[this.currentDroneId][idx].setChecked(checked);
      this.$emit("updateWaypoint");
    },
    deleteWaypoint() {
      this.$emit("deleteWaypoint");
    },
    clearWaypoint() {
      this.$emit("clearWaypoint");
    },
    groupCheck(idx, checked) {
      this.groupList[this.currentDroneId][idx].setChecked(checked);

      for (let i = 0; i < this.waypointList[this.currentDroneId].length; i++) {
        if (
          this.waypointList[this.currentDroneId][i].getGroupNum() ==
          this.groupList[this.currentDroneId][idx].getGroupNum()
        )
          this.waypointList[this.currentDroneId][i].setChecked(checked);
      }
      this.$emit("updateWaypoint");
    },
    deleteGroup() {
      this.$emit("deleteGroup");
    },
    rotateGroup() {
      this.$emit("rotateGroup");
    },
    mergeGroup() {
      this.$emit("mergeGroup");
    },
    setHeight(height) {
      this.currentHeight = height;
      this.$emit("heightChanged", this.currentHeight);
    },
    setLowerHeight(event) {
      this.$emit("lowerHeightChanged", parseFloat(event.target.value));
    },
    setUpperHeight(event) {
      this.$emit("upperHeightChanged", parseFloat(event.target.value));
    },
    handleGroupTableEvent(idx, event) {
      if (event.type === "mousedown" || event.type === "touchstart") {
        this.isMouseDown = true;
        this.activeIdx = idx;
      } else if (
        event.type === "mouseup" ||
        event.type === "touchend" ||
        event.type === "touchcancel"
      ) {
        if (this.hoveredIdx != null && this.activeIdx != this.hoveredIdx) {
          let swapIdx = [this.activeIdx, this.hoveredIdx];
          if (this.activeIdx > this.hoveredIdx)
            swapIdx = [this.hoveredIdx, this.activeIdx];
          this.$emit("swapGroups", swapIdx);
        }
        this.isMouseDown = false;
        this.activeIdx = null;
        this.hoveredIdx = null;
      } else if (event.type === "mousemove") {
        if (this.isMouseDown && this.activeIdx != idx) {
          this.hoveredIdx = idx;
        }
      } else if (event.type === "touchmove") {
        if (event.touches.length > 0) {
          const touch = event.touches[0];
          let element = document.elementFromPoint(touch.clientX, touch.clientY);
          while (element && !element.dataset.idx) {
            element = element.parentElement;
          }
          if (element && element.dataset.idx) {
            const currentIdx = Number(element.dataset.idx);
            if (this.isMouseDown && this.activeIdx !== currentIdx) {
              this.hoveredIdx = currentIdx;
            }
          }
        }
      }
    },
    getGroupTableStyle(idx) {
      if (this.hoveredIdx === idx) {
        return { backgroundColor: "rgba(1, 250, 254, 0.5)" };
      } else if (this.activeIdx === idx) {
        return { backgroundColor: "rgba(1, 250, 254, 1)" };
      }
      return {};
    },
    changeWaypointNumber(index) {
      if (
        confirm(
          this.$t("droneAlert.changeMissionPointToWaypointIndex", {
            index: index + 1,
          })
        )
      ) {
        this.$emit("changeWaypointNumber", this.currentDroneId, index + 1);
      } else {
        return false;
      }
    },
    scrollToWaypoint(waypointNumber) {
      if (waypointNumber != 0) {
        let waypointElement = this.$refs[`waypoint${waypointNumber - 1}`][0];
        if (waypointElement) {
          waypointElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    },
  },
  watch: {
    isVisible(newValue) {
      if (!newValue) {
        this.isPlanningClicked = false;
        this.isAreaSearchClicked = false;
        this.setFalseFit();
      }
    },
    currentDroneWaypoint(waypointNumber) {
      this.scrollToWaypoint(waypointNumber);
    },
  },
};
</script>

<style lang="scss">
.inspection_waypoint_table_container {
  display: flex;
  flex-direction: column;
  height: 310px;
  width: 300px;
  position: fixed;
  bottom: 50px;
  right: 10px;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  padding: 10px;
}

.inspection_waypoint_head {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100%;
  background-color: rgba(52, 52, 52, 0.6);
  border-radius: 5px;
}

.inspection_waypoint_head_item {
  @include flexbox;
  height: 100%;
  width: 30px;
  color: rgb(120, 120, 120);
  font-size: 1.4rem;
}

.inspection_waypoint_item_container {
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 100%;
  overflow: auto;
}
.inspection_waypoint_item_container::-webkit-scrollbar {
  width: 10px;
}

.inspection_waypoint_item_container::-webkit-scrollbar-track {
  background: transparent;
}

.inspection_waypoint_item_container::-webkit-scrollbar-thumb {
  background: rgba(52, 52, 52, 0.8);
  border-radius: 5px;
}

.inspection_waypoint_item_container::-webkit-scrollbar-thumb:hover {
  background: rgba(52, 52, 52, 1);
}

.inspection_waypoint_item_wrapper {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100%;
}
.inspection_waypoint_item_wrapper.on {
  background: $main_color;
  color: black;
}

.inspection_waypoint_item {
  @include flexbox;
  height: 30px;
  width: 30px;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $main_white;
}

.inspection_waypoint_item_checkbox {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  box-shadow: $main_box_shadow;
  background-color: rgb(32, 32, 32);
  cursor: pointer;
}

.inspection_waypoint_item_checked {
  background-image: url("@/../public/images/check.svg");
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main_color;
}

.inspection_waypoint_table_button_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 5px;
}

.inspection_waypoint_table_button {
  @include flexbox;
  height: 30px;
  width: 130px;
  font-size: 1.4rem;
}
.inspection_waypoint_table_collision_button {
  @include flexbox;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
}
.inspection_waypoint_table_collision_button:hover {
  color: rgb(23, 23, 23);
  background-color: $main_color;
}

.inspection_group_table_color_bar {
  height: 20px;
  width: 45px;
  background-color: $main_color;
  border-radius: 1px;
}
</style>
